import EquityCheckActions from "components/EquityCheck/Actions";
import EquityCheckActionsTitle from "components/EquityCheck/Actions/Title";
import { EquityCheckType } from "ts/types";

export default function EquityCheckSuccess({
  equityCheck,
}: {
  equityCheck: EquityCheckType;
}) {
  return (
    <div className="overflow-hidden lg:pt-10">
      <div className="w-screen pl-4 lg:pl-0">
        <EquityCheckActionsTitle />
      </div>

      <div className="no-scroll-bar flex flex-row flex-nowrap space-x-4 overflow-y-scroll pb-10 pl-5 pr-5 lg:-ml-4 lg:pr-10">
        <EquityCheckActions equityCheck={equityCheck} />
      </div>
    </div>
  );
}
