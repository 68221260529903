export default function HeroWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="628"
      height="480"
      viewBox="0 0 628 480"
      fill="none"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314.008 -41L0 172.929H99.2283V480L314.008 15.8136L528.788 480V172.929H628L314.008 -41Z"
        fill="white"
      />
    </svg>
  );
}
