import {
  ArrowLeftCircleIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { identifyTrackingUser } from "analytics";
import EquityChart from "components/Dashboard/Stats/Chart";
import StatsBar from "components/Dashboard/Stats/StatsBar";
import NavigationDesktop from "components/Navigation/Desktop";
import NavigationMobile from "components/Navigation/Mobile";
import { APP_URLS } from "settings";
import { useUserStore } from "store/User";
import { classNames } from "utils/helpers";

export default function Dashboard({
  title,
  children,
  showStats = false,
  overlap = false,
}: {
  title?: string;
  showStats?: boolean;
  overlap?: boolean;
  children?: JSX.Element;
}) {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const userAddress = useUserStore((state) => state.propertyAddress);
  const equityCheck = useUserStore((state) => state.equityCheck);
  const equityCheckIsSuccess = useUserStore(
    (state) => state.equityCheckIsSuccess
  );
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (user) {
      identifyTrackingUser(user.id, {
        $name: user?.fullName,
        $email: user?.email,
        AUID: user?.id,
      });
    }
  }, [user]);

  const toggleNav = () => (navOpen ? setNavOpen(false) : setNavOpen(true));

  return (
    <>
      <NavigationMobile navOpen={navOpen} toggleNav={toggleNav} />

      <div className="min-h-full">
        <div className={classNames("brand-hero", overlap ? "pb-32" : "")}>
          <NavigationDesktop navOpen={navOpen} toggleNav={toggleNav} />

          <header className="pb-4 pt-4 md:pb-10 md:pt-20">
            <div className="mx-auto px-4 2xl:container sm:px-6 lg:px-8">
              <div className="flex justify-between">
                <div className="grow">
                  <div className="flex items-center">
                    <h1
                      className="pb-4 text-3xl text-white md:text-6xl"
                      dangerouslySetInnerHTML={{ __html: title || "" }}
                    ></h1>
                  </div>
                </div>
                {userAddress?.shortAddress && (
                  <div className="text-right text-sm font-semibold text-white md:text-base">
                    <span className="uppercase leading-tight">My home</span>
                    <p className="leading-tight opacity-70">
                      {userAddress.shortAddress}
                      <br />
                      {userAddress.townOrCity}
                      <br />
                      {userAddress.postcode}
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-4 md:mt-0">
                {window.location.pathname === "/" ? (
                  <p className="flex items-center text-sm text-white md:text-base">
                    <CalendarDaysIcon className="block h-6 w-6 md:h-7 md:w-7" />{" "}
                    <span className="pl-2 uppercase">
                      Last updated:{" "}
                      {equityCheckIsSuccess && equityCheck?.dataAccuracyDate
                        ? equityCheck.dataAccuracyDate
                        : "Information being collected"}
                    </span>
                  </p>
                ) : (
                  <Link
                    to={APP_URLS.DASHBOARD}
                    className="inline-flex items-center text-sm text-white hover:text-brand-green-bright md:text-base"
                  >
                    <ArrowLeftCircleIcon className="block h-6 w-6 md:h-7 md:w-7" />{" "}
                    <span className="pl-2 uppercase">Dashboard</span>
                  </Link>
                )}
              </div>
            </div>
            {showStats && equityCheckIsSuccess && (
              <div className="mx-auto mt-6 w-full 2xl:container md:mt-8 lg:px-6 lg:px-8">
                <div className="flex items-center justify-center px-6 pb-6 md:pb-10 lg:hidden">
                  <EquityChart
                    chartID="propertyChartHead"
                    equityCheck={equityCheck}
                  />
                </div>
                <StatsBar />
              </div>
            )}
          </header>
        </div>

        <main
          className={classNames("overflow-hidden", overlap ? "-mt-32" : "my-8")}
        >
          <div className="mx-auto pb-12 2xl:container">
            <div className="flex flex-col space-y-12">{children}</div>
          </div>
        </main>
      </div>
    </>
  );
}
