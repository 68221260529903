import Card from "components/Card";
import CardBody from "components/Card/Body";
import Container from "components/Dashboard/Container";

export default function AccountDelete() {
  return (
    <Container>
      <Card
        title="Delete account"
        subtitle="Request to have your account deactivated"
      >
        <CardBody>
          <div className="text-base text-brand-copy-light">
            <p className="mb-2">
              We'll be sad to see you go, but our team will be happy to assist
              with your request.
            </p>
            <p>
              Simply contact us at{" "}
              <a href="mailto:info@ahauz.com">info@ahauz.com</a> using the email
              address that’s associated with your account so that we can verify
              and process your request.
            </p>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}
