import { useQuery } from "@apollo/client";
import { Chart, ChartType } from "chart.js/auto";
import { useEffect, useRef, useState } from "react";

import { QUERY_EQUITY_TIMELINE } from "api/queries/timeline";
import Container from "components/Dashboard/Container";
import Loading from "components/Loading";
import { CHART_DATASET_TYPES } from "../consts";
import { chartOptions, getChartDataFromQuery } from "../setup";
import { filterTooltip } from "../tooltips";
import { DatasetMapType, StateChartDataType } from "../types";

export default function TimelineChart() {
  const chartContainer = useRef(null);
  const [loadingChart, setLoadingChart] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState<StateChartDataType | null>(null);
  const [theChart, setTheChart] = useState<any>(null);

  const { error: queryError, data: queryData } = useQuery(
    QUERY_EQUITY_TIMELINE,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    const _chartData = getChartDataFromQuery(queryData);
    setChartData(_chartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  useEffect(() => {
    let thisChart: any = null;

    if (chartData && chartContainer && chartContainer.current) {
      setLoadingChart(false);

      setTimeout(() => {
        setShowChart(true);
      }, 3000);

      const chartConfig = {
        type: "line" as ChartType,
        data: chartData,
        options: chartOptions,
      };
      thisChart = new Chart(chartContainer.current, chartConfig);
      setTheChart(thisChart);
    }

    return () => {
      if (thisChart) {
        thisChart.destroy();
        setTheChart(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const renderLoading = () => (
    <div className="px-8 py-20">
      <Loading />
    </div>
  );

  const renderTimeline = () => {
    if (queryError) {
      return (
        <div className="space-y-2 px-8 py-20 text-center">
          <p>Sorry, we can not load your Equity Timeline right now.</p>
          <p>Please try again later.</p>
        </div>
      );
    }
    if (loadingChart) {
      return renderLoading();
    }
    return <></>;
  };

  return (
    <div className="px-0 lg:px-8">
      <Container>
        <>
          {renderTimeline()}

          {chartData && (
            <div className="flex flex-col overflow-hidden">
              <div className="flex justify-center space-x-4 px-4 pb-0 pt-6 sm:px-6 md:justify-start md:space-x-6">
                {CHART_DATASET_TYPES.map(
                  (datasetType: DatasetMapType, i: number) => (
                    <span
                      key={`dt-${i}`}
                      className="flex items-center text-sm font-semibold uppercase md:text-base"
                      onClick={() => filterTooltip(theChart, i)}
                    >
                      <span
                        className={`h-4 w-4 bg-${datasetType.color} mr-2 rounded-full`}
                      ></span>
                      {datasetType.label}
                    </span>
                  )
                )}
              </div>

              {showChart ? <></> : renderLoading()}

              <div
                className={`no-scroll-bar overflow-y-hidden overflow-x-scroll scroll-smooth pb-5 opacity-${
                  showChart ? 1 : 0
                }`}
              >
                <div className="relative mb-1 w-[980px] px-6 py-5 lg:w-full">
                  <canvas
                    id="equityTimeline"
                    ref={chartContainer}
                    className="no-highlight cursor-pointer"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </Container>
    </div>
  );
}
