import { useEffect } from "react";

import { trackPage } from "analytics";
import Dashboard from "components/Dashboard";
import TimelineEquityCTAs from "./components/EquityCTAs";
import TimelineChart from "./components/TimelineChart";

export default function EquityTimeline() {
  useEffect(() => {
    trackPage("Timeline");
  }, []);

  return (
    <Dashboard title="Timeline" overlap>
      <>
        <TimelineChart />
        <TimelineEquityCTAs />
      </>
    </Dashboard>
  );
}
