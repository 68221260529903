import { CommonProps } from "ts/types";
import { classNames } from "utils/helpers";

export default function Card({ title, subtitle, children }: CommonProps) {
  return (
    <>
      {title && (
        <div className="px-4 py-5 sm:px-6">
          <h3
            className={classNames(
              children ? "text-xl" : "text-2xl",
              "font-medium leading-6"
            )}
          >
            {title}
          </h3>
          {subtitle && (
            <p
              className={classNames(
                children ? "text-base" : "text-md",
                "text-green-dark mt-1"
              )}
              dangerouslySetInnerHTML={{ __html: subtitle || "" }}
            ></p>
          )}
        </div>
      )}
      {children && children}
    </>
  );
}
