export const ENQUIRY_PRODUCT_OTHER = "e_other";
export const ENQUIRY_EQUITY_RELEASE = "e_equity_release";

export const ENQUIRY_PRODUCT_OPTIONS = [
  { name: "Remortgage", value: "e_remortgage" },
  { name: "Buy my next home", value: "e_buy_next_home" },
  { name: "Buy to Let", value: "e_buy_to_let" },
  { name: "Let to Buy", value: "e_let_to_buy" },
  { name: "Equity Release", value: ENQUIRY_EQUITY_RELEASE },
  { name: "Home improvements", value: "e_home_improvements" },
  { name: "Debt consolidation", value: "e_debt_consolidation" },
  { name: "Bridging", value: "e_bridging" },
  { name: "Personal loan", value: "e_personal_loan" },
  { name: "Insurance", value: "e_mortgage_protection" },
  { name: "Other", value: ENQUIRY_PRODUCT_OTHER },
];
