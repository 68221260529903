import Wrapper from "components/Dashboard/Wrapper";

export default function EquityCheckSubmitted() {
  return (
    <Wrapper>
      <>
        <h2 className="mb-4 text-3xl">Thanks for submitting your details.</h2>
        <p className="mb-2 text-base">
          It's taking a bit longer for us to assess your home equity.
        </p>
        <p className="mb-2 text-base">
          As soon as we have your dashboard ready, we will update you. Please
          look out for an email from us.
        </p>
      </>
    </Wrapper>
  );
}
