import CRMDialog from "components/CRM/Dialog";

export default function CRMRescheduleAppointment({
  rescheduleURL,
}: {
  rescheduleURL: string;
}) {
  return (
    <CRMDialog title="Reschedule your appointment">
      <div
        style={{
          width: "345px",
          height: "675px",
          position: "relative",
          left: "-3px",
          top: "-30px",
        }}
      >
        <iframe
          title="hubspot"
          style={{
            width: "350px",
            height: "700px",
          }}
          src={rescheduleURL}
        />
      </div>
    </CRMDialog>
  );
}
