import { useState } from "react";

import Button from "components/CTA/Button";
import {
  MortgageQaOptionsType,
  MortgageQaType,
} from "pages/Account/AccountVerification/types";
import { asCurrency } from "utils/currency";
import FormInputCheckbox from "components/Form/Input/CheckBox";
import FormInputError from "components/Form/Input/Error";

interface PropertiesType {
  mortgageQa: MortgageQaType;
  loading: boolean;
  error: Function;
  handleMortgageSubmit: Function;
}

export default function Mortgage({
  mortgageQa,
  loading,
  error,
  handleMortgageSubmit,
}: PropertiesType) {
  const [mortgageAnswer, setMortgageAnswer] = useState<MortgageQaOptionsType[]>(
    []
  );
  const [validationError, setValidationError] = useState<boolean>(false);

  const handleAnswerQuestion = () => {
    setValidationError(false);

    if (mortgageAnswer.length === 0) {
      setValidationError(true);
      return;
    }

    handleMortgageSubmit(mortgageAnswer);
  };

  const mortgageExistingAnswer = (answer: MortgageQaOptionsType) =>
    mortgageAnswer.find((ma) => {
      return (
        ma.balanceAmount === answer.balanceAmount &&
        ma.startedAt === answer.startedAt
      );
    });

  const mortgageAnswerExists = (answer: MortgageQaOptionsType) =>
    !!mortgageExistingAnswer(answer);

  const handleMortgageQaAnswer = (answer: MortgageQaOptionsType) => {
    if (answer.startedAt === "EMPTY") {
      return setMortgageAnswer([answer]);
    }
    const answerExists = mortgageAnswerExists(answer);
    if (answerExists) {
      const existingAnswer = mortgageExistingAnswer(answer);
      if (existingAnswer) {
        const index = mortgageAnswer.indexOf(existingAnswer);
        mortgageAnswer.splice(index, 1);
      }
    } else {
      mortgageAnswer.push(answer);
    }

    const emptyAnswer = mortgageAnswer.find((ma) => ma.startedAt === "EMPTY");
    if (emptyAnswer) {
      const emptyIndex = mortgageAnswer.indexOf(emptyAnswer);
      mortgageAnswer.splice(emptyIndex, 1);
    }
    setMortgageAnswer(JSON.parse(JSON.stringify(mortgageAnswer)));
  };

  if (!mortgageQa) {
    return null;
  }

  return (
    <div className="mt-4">
      <p className="mb-1 text-center text-lg">
        {mortgageQa.question.split(":")[0]}:
      </p>
      <p className="mb-4 text-center text-lg font-semibold">
        {mortgageQa.question.split(":")[1]}
      </p>

      <div className="mb-5">
        {mortgageQa.options.map(
          (option: MortgageQaOptionsType, index: number) => {
            const checked = mortgageAnswerExists(option);
            return (
              <div className="mt-4 flex items-center" key={index}>
                <FormInputCheckbox
                  id={`mortgage-q-${index}`}
                  label={
                    option.startedAt === "EMPTY"
                      ? "No mortgage at the property address used for sign-up"
                      : `Balance amount: £${asCurrency(
                          option.balanceAmount
                        )} / Start year: ${option.startYear}`
                  }
                  checked={checked}
                  onChange={() => handleMortgageQaAnswer(option)}
                />
              </div>
            );
          }
        )}

        {validationError && (
          <FormInputError error="Please provide an answer to proceed" />
        )}
      </div>

      {error()}

      <div className="mt-8 flex justify-end">
        <Button
          label="Submit"
          loading={loading}
          onClick={handleAnswerQuestion}
        />
      </div>
    </div>
  );
}
