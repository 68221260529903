import { useEffect } from "react";

import { trackPage } from "analytics";
import Dashboard from "components/Dashboard";
import Wrapper from "components/Dashboard/Wrapper";
import AccountDelete from "pages/Account/AccountProfile/components/AccountDelete";
import Password from "pages/Account/AccountProfile/components/Password";
import PersonalInformation from "pages/Account/AccountProfile/components/PersonalInformation";

export default function AccountProfile() {
  useEffect(() => {
    trackPage("Profile");
  }, []);

  return (
    <Dashboard title="Profile">
      <Wrapper>
        <div className="space-y-12">
          <PersonalInformation />
          <Password />
          <AccountDelete />
        </div>
      </Wrapper>
    </Dashboard>
  );
}
