export default function IconConvo() {
  return (
    <svg
      width="96"
      height="66"
      viewBox="0 0 96 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.0796 32.6268C92.9288 29.1941 95.9591 24.4435 95.9591 19.1916C95.9591 8.58573 83.735 0 68.5837 0C59.4929 0 51.4122 3.13288 46.4648 7.87948C51.7165 9.1934 56.4631 11.3122 60.606 14.2438C68.3829 19.6967 72.624 27.1739 72.624 35.1516C72.624 36.1617 72.5215 37.2744 72.4228 38.2845C73.8353 38.1819 75.2519 37.882 76.6644 37.6769C79.592 39.4958 84.7451 42.3249 90.4036 43.84C92.1199 44.345 93.5364 42.3249 92.4237 40.8097C90.4036 37.9807 88.8884 34.548 88.0796 32.6264L88.0796 32.6268Z"
        fill="#99F2DA"
      />
      <path
        d="M33.7349 11.415C15.0483 11.415 0 22.0209 0 35.152C0 41.6149 3.73651 47.474 9.69844 51.8184C8.79094 54.1424 6.86939 58.384 4.4468 61.8166C3.13288 63.6356 4.84926 66.0582 6.97202 65.5531C13.8414 63.7342 20.2062 60.1989 23.8394 57.9775C26.9723 58.6838 30.3024 58.9876 33.7391 58.9876C52.4257 58.9876 67.474 48.3817 67.474 35.2506C67.5726 22.0205 52.4216 11.4146 33.7351 11.4146L33.7349 11.415ZM17.7785 39.8004C15.1507 39.8004 13.1305 37.6776 13.1305 35.1524C13.1305 32.5245 15.2533 30.5044 17.7785 30.5044C20.3037 30.5044 22.4265 32.6272 22.4265 35.1524C22.4226 37.6776 20.3037 39.8004 17.7785 39.8004ZM33.7349 39.8004C31.107 39.8004 29.0868 37.6776 29.0868 35.1524C29.0868 32.5245 31.2096 30.5044 33.7349 30.5044C36.3627 30.5044 38.3829 32.6272 38.3829 35.1524C38.4855 37.6776 36.3627 39.8004 33.7349 39.8004ZM49.7972 39.8004C47.1694 39.8004 45.1492 37.6776 45.1492 35.1524C45.1492 32.5245 47.272 30.5044 49.7972 30.5044C52.4251 30.5044 54.4453 32.6272 54.4453 35.1524C54.4413 37.6776 52.4211 39.8004 49.7972 39.8004Z"
        fill="#00DEA3"
      />
    </svg>
  );
}
