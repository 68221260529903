import React from "react";

import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";

export const isValidEmail = (email: string | null): boolean => {
  if (!email) {
    return false;
  }
  // Super simple email validation, rely on backend
  const regex = /^\S+@\S+\.\S+$/;
  return regex.test(email);
};

export default function FormInputEmail({
  id = "email",
  label = "Email",
  onChange,
  error,
  register,
}: FormInputProps) {
  const inputProps =
    id && register ? { ...register(id, { required: true }) } : {};

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          name={id}
          type="email"
          autoComplete="email"
          required
          className={STYLE_INPUT}
          onChange={handleOnChange}
          {...inputProps}
        />
        <FormInputError error={error} />
      </div>
    </div>
  );
}
