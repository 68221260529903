export default function RegulatedFlag() {
  return (
    <svg
      viewBox="0 0 158 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block h-full w-[200px]"
    >
      <path
        d="M9.62803 0.00453088C9.61446 0.00834626 9.60185 0.0140692 9.58924 0.0197924C7.20255 0.960294 4.5928 1.91413 0.241374 2.04958C0.107541 2.05339 0.000861994 2.16213 0.000861994 2.29376C-0.00107749 3.10358 0.000861994 7.70123 0.000861994 8.45186C0.000861994 12.5153 1.24899 15.3501 3.0809 17.3111C4.91286 19.2732 7.31217 20.3549 9.61264 20.989V20.99C9.65822 21.0033 9.70671 21.0033 9.75229 20.99C12.0536 20.3547 14.452 19.274 16.284 17.3121C18.116 15.35 19.3641 12.5151 19.3641 8.45281C19.3641 7.70214 19.366 3.1037 19.3641 2.29472V2.29376C19.3641 2.16213 19.2574 2.05435 19.1236 2.04958C14.7721 1.91318 12.1623 0.960294 9.77569 0.0197924C9.72914 0.000715492 9.67774 -0.00500698 9.62828 0.00453088H9.62803ZM9.68234 0.508162C12.007 1.42195 14.6574 2.34909 18.8673 2.51507C18.8693 3.5433 18.8673 7.72695 18.8673 8.45168C18.8673 12.4177 17.6667 15.1047 15.9191 16.9752C14.19 18.8266 11.9061 19.8788 9.68234 20.5007C7.45859 19.8788 5.17475 18.8267 3.44555 16.9752C1.69795 15.1037 0.49734 12.4168 0.49734 8.45168C0.49734 7.72675 0.495401 3.54308 0.49734 2.51507C4.70825 2.3491 7.35779 1.42195 9.68234 0.508162ZM14.3841 6.83401C14.3201 6.84069 14.2619 6.87121 14.2212 6.91795L7.95333 13.5568L5.15259 10.3443H5.15162C5.11477 10.2833 5.05173 10.2413 4.979 10.2299C4.90723 10.2175 4.83353 10.2385 4.77825 10.2852C4.72297 10.331 4.69097 10.3997 4.69097 10.4712C4.69097 10.5437 4.72394 10.6114 4.78019 10.6572L7.75943 14.0758H7.75846C7.80501 14.1273 7.86999 14.1569 7.93884 14.1588C8.00867 14.1597 8.07559 14.133 8.12311 14.0834L14.5771 7.24626H14.5781C14.6479 7.17376 14.6654 7.06693 14.6227 6.97536C14.58 6.88474 14.485 6.82846 14.3841 6.83419L14.3841 6.83401Z"
        fill="#052A2E"
      />
      <path
        d="M31.4429 9.37378L29.0923 4.27661H27.9633L25.6216 9.37378H26.7638L27.2313 8.32301H29.8156L30.2874 9.37378H31.4429ZM28.519 5.44201L29.4407 7.49004H27.6061L28.519 5.44201Z"
        fill="#052A2E"
      />
      <path
        d="M34.4636 9.45019C36.2232 9.45019 36.8495 8.53316 36.8495 7.3907V4.27661H35.7646V7.31428C35.7646 8.08993 35.4647 8.59048 34.4636 8.59048C33.4625 8.59048 33.1626 8.08993 33.1626 7.31428V4.27661H32.0778V7.3907C32.0778 8.53316 32.704 9.45019 34.4636 9.45019Z"
        fill="#052A2E"
      />
      <path
        d="M40.8536 9.37378V5.09812H42.6529V4.27661H37.9738V5.09812H39.7732V9.37378H40.8536Z"
        fill="#052A2E"
      />
      <path
        d="M44.8666 9.37378V7.18819H47.4597V9.37378H48.5446V4.27661H47.4597V6.34757H44.8666V4.27661H43.7817V9.37378H44.8666Z"
        fill="#052A2E"
      />
      <path
        d="M52.5841 9.45019C54.3525 9.45019 55.4991 8.36886 55.4991 6.8252C55.4991 5.27389 54.3525 4.2002 52.5841 4.2002C50.8112 4.2002 49.669 5.27389 49.669 6.8252C49.669 8.36886 50.8112 9.45019 52.5841 9.45019ZM52.5841 8.59048C51.4463 8.59048 50.7936 7.86068 50.7936 6.8252C50.7936 5.78207 51.4463 5.05991 52.5841 5.05991C53.7219 5.05991 54.3701 5.78207 54.3701 6.8252C54.3701 7.86068 53.7219 8.59048 52.5841 8.59048Z"
        fill="#052A2E"
      />
      <path
        d="M59.6665 7.14998C60.672 7.03153 61.2365 6.5348 61.2365 5.74768C61.2365 4.83447 60.4867 4.27661 59.1725 4.27661H56.6235V9.37378H57.6996V7.17672H58.4272L60.3324 9.37378H61.6422L59.6665 7.14998ZM59.1593 5.11723C59.737 5.11723 60.1075 5.33502 60.1075 5.76297C60.1075 6.19092 59.737 6.41253 59.1593 6.41253H57.6996V5.11723H59.1593Z"
        fill="#052A2E"
      />
      <path
        d="M63.5516 9.37378V4.27661H62.4667V9.37378H63.5516Z"
        fill="#052A2E"
      />
      <path
        d="M67.0618 9.45019C68.526 9.45019 69.3198 8.77006 69.3198 7.90271C69.3198 7.37923 69.0111 6.81373 67.9967 6.58065L66.4929 6.23677C66.0166 6.12596 65.9284 5.86996 65.9284 5.69037C65.9284 5.28535 66.3738 5.04463 66.9163 5.04463C67.5469 5.04463 68.0585 5.28153 68.1908 5.88142L69.2404 5.62159C69.0508 4.76952 68.1643 4.2002 66.9163 4.2002C65.6021 4.2002 64.7994 4.89943 64.7994 5.72858C64.7994 6.23677 65.0685 6.8252 66.1181 7.05827L67.6219 7.40216C68.1026 7.51297 68.1952 7.75751 68.1952 7.94092C68.1952 8.36504 67.7586 8.60576 67.0618 8.60576C66.3165 8.60576 65.7697 8.34212 65.6285 7.72694L64.5878 7.99059C64.7774 8.85413 65.7079 9.45019 67.0618 9.45019Z"
        fill="#052A2E"
      />
      <path
        d="M74.4441 9.37378V8.53316H71.4453V7.18819H74.2677V6.34757H71.4453V5.11723H74.4441V4.27661H70.3604V9.37378H74.4441Z"
        fill="#052A2E"
      />
      <path
        d="M77.9543 9.37378C79.7272 9.37378 80.8694 8.29626 80.8694 6.82902C80.8694 5.35413 79.7272 4.27661 77.9543 4.27661H75.6126V9.37378H77.9543ZM76.6975 5.11723H77.9543C79.0921 5.11723 79.7404 5.86231 79.7404 6.82902C79.7404 7.78808 79.0921 8.53316 77.9543 8.53316H76.6975V5.11723Z"
        fill="#052A2E"
      />
      <path
        d="M85.5217 9.45019C86.0465 9.45019 86.708 9.32028 87.2769 8.93437L87.7399 9.37378H88.9307L87.8458 8.35358C88.1545 7.95238 88.3441 7.45565 88.4059 6.88633L87.5062 6.79081C87.4621 7.14233 87.3651 7.45947 87.2019 7.7384L86.0994 6.70675C86.8182 6.31701 87.2593 5.9884 87.2593 5.39616C87.2593 4.71602 86.611 4.2002 85.6981 4.2002C84.7587 4.2002 84.0928 4.72749 84.0928 5.46493C84.0928 5.90434 84.3221 6.21384 84.6353 6.51952C83.9296 6.89397 83.5195 7.34485 83.5195 7.98295C83.5195 8.96875 84.5118 9.45019 85.5217 9.45019ZM85.4996 6.18709C85.2394 5.94637 85.0586 5.77443 85.0586 5.46493C85.0586 5.15926 85.3056 4.96057 85.6849 4.96057C86.0862 4.96057 86.3155 5.14015 86.3155 5.43437C86.3155 5.74386 86.0597 5.91199 85.4996 6.18709ZM85.5217 8.68218C85.0763 8.68218 84.5074 8.46439 84.5074 7.94474C84.5074 7.54354 84.7631 7.34102 85.2703 7.08884L86.6507 8.35358C86.2449 8.61723 85.7995 8.68218 85.5217 8.68218Z"
        fill="#052A2E"
      />
      <path
        d="M94.3547 7.14998C95.3602 7.03153 95.9247 6.5348 95.9247 5.74768C95.9247 4.83447 95.175 4.27661 93.8608 4.27661H91.3118V9.37378H92.3878V7.17672H93.1155L95.0206 9.37378H96.3304L94.3547 7.14998ZM93.8475 5.11723C94.4253 5.11723 94.7957 5.33502 94.7957 5.76297C94.7957 6.19092 94.4253 6.41253 93.8475 6.41253H92.3878V5.11723H93.8475Z"
        fill="#052A2E"
      />
      <path
        d="M101.239 9.37378V8.53316H98.2398V7.18819H101.062V6.34757H98.2398V5.11723H101.239V4.27661H97.1549V9.37378H101.239Z"
        fill="#052A2E"
      />
      <path
        d="M105.313 7.46712H106.738V8.33447C106.213 8.53316 105.75 8.59048 105.274 8.59048C103.964 8.59048 103.311 7.85685 103.311 6.82137C103.311 5.78971 103.959 5.05991 105.053 5.05991C105.9 5.05991 106.411 5.42672 106.725 6.00369L107.726 5.56428C107.276 4.72749 106.367 4.2002 105.053 4.2002C103.329 4.2002 102.187 5.28153 102.187 6.82137C102.187 8.36504 103.333 9.45019 105.274 9.45019C105.966 9.45019 106.91 9.31646 107.818 8.87705V6.63415H105.313V7.46712Z"
        fill="#052A2E"
      />
      <path
        d="M111.421 9.45019C113.181 9.45019 113.807 8.53316 113.807 7.3907V4.27661H112.722V7.31428C112.722 8.08993 112.422 8.59048 111.421 8.59048C110.42 8.59048 110.12 8.08993 110.12 7.31428V4.27661H109.035V7.3907C109.035 8.53316 109.662 9.45019 111.421 9.45019Z"
        fill="#052A2E"
      />
      <path
        d="M118.883 9.37378V8.53316H116.104V4.27661H115.02V9.37378H118.883Z"
        fill="#052A2E"
      />
      <path
        d="M125.436 9.37378L123.085 4.27661H121.956L119.615 9.37378H120.757L121.224 8.32301H123.809L124.281 9.37378H125.436ZM122.512 5.44201L123.434 7.49004H121.599L122.512 5.44201Z"
        fill="#052A2E"
      />
      <path
        d="M127.998 9.37378V5.09812H129.797V4.27661H125.118V5.09812H126.918V9.37378H127.998Z"
        fill="#052A2E"
      />
      <path
        d="M135.01 9.37378V8.53316H132.011V7.18819H134.834V6.34757H132.011V5.11723H135.01V4.27661H130.926V9.37378H135.01Z"
        fill="#052A2E"
      />
      <path
        d="M138.52 9.37378C140.293 9.37378 141.435 8.29626 141.435 6.82902C141.435 5.35413 140.293 4.27661 138.52 4.27661H136.178V9.37378H138.52ZM137.263 5.11723H138.52C139.658 5.11723 140.306 5.86231 140.306 6.82902C140.306 7.78808 139.658 8.53316 138.52 8.53316H137.263V5.11723Z"
        fill="#052A2E"
      />
      <path
        d="M148.024 6.6991C148.469 6.48131 148.738 6.10685 148.738 5.6598C148.738 4.82683 147.997 4.27661 146.683 4.27661H144.257V9.37378H146.947C148.262 9.37378 149.003 8.82356 149.003 7.94856C149.003 7.36777 148.654 6.936 148.024 6.6991ZM146.665 5.1134C147.243 5.1134 147.609 5.33502 147.609 5.74004C147.609 6.14889 147.243 6.36668 146.665 6.36668H145.329V5.1134H146.665ZM145.329 8.53699V7.20347H146.93C147.503 7.20347 147.874 7.42509 147.874 7.87214C147.874 8.31537 147.503 8.53699 146.93 8.53699H145.329Z"
        fill="#052A2E"
      />
      <path
        d="M152.045 6.67236L150.489 4.27279H149.285L151.503 7.61613V9.37378H152.583V7.61613L154.797 4.27279H153.607L152.045 6.67236Z"
        fill="#052A2E"
      />
      <path
        d="M42.7359 17.7823V16.1354H44.962V15.1702H42.7359V14.2456H45.1011V13.2804H41.6506V17.7823H42.7359Z"
        fill="#052A2E"
      />
      <path
        d="M46.8784 17.7823V13.2804H45.7932V17.7823H46.8784Z"
        fill="#052A2E"
      />
      <path
        d="M48.6731 17.7823V14.7011L50.2279 17.7823H51.6367V13.2804H50.5723V16.3615L49.014 13.2804H47.6053V17.7823H48.6731Z"
        fill="#052A2E"
      />
      <path
        d="M56.9444 17.7823L55.1357 13.2804H53.9044L52.1061 17.7823H53.2365L53.5392 16.9892H55.487L55.7896 17.7823H56.9444ZM54.5096 14.448L55.1253 16.0477H53.9009L54.5096 14.448Z"
        fill="#052A2E"
      />
      <path
        d="M58.4748 17.7823V14.7011L60.0296 17.7823H61.4383V13.2804H60.3739V16.3615L58.8157 13.2804H57.4069V17.7823H58.4748Z"
        fill="#052A2E"
      />
      <path
        d="M64.44 17.8498C65.5948 17.8498 66.4539 17.2153 66.7426 16.1894L65.6852 15.862C65.5357 16.4796 65.0939 16.8643 64.44 16.8643C63.6643 16.8643 63.2052 16.294 63.2052 15.5313C63.2052 14.7686 63.6643 14.1949 64.44 14.1949C65.0939 14.1949 65.5357 14.583 65.6852 15.2006L66.7426 14.8733C66.4539 13.8473 65.5948 13.2129 64.44 13.2129C63.0139 13.2129 62.0747 14.2118 62.0747 15.5313C62.0747 16.8508 63.0139 17.8498 64.44 17.8498Z"
        fill="#052A2E"
      />
      <path
        d="M68.433 17.7823V13.2804H67.3477V17.7823H68.433Z"
        fill="#052A2E"
      />
      <path
        d="M73.7373 17.7823L71.9285 13.2804H70.6972L68.8989 17.7823H70.0294L70.332 16.9892H72.2799L72.5825 17.7823H73.7373ZM71.3024 14.448L71.9181 16.0477H70.6937L71.3024 14.448Z"
        fill="#052A2E"
      />
      <path
        d="M77.4764 17.7823V16.8137H75.285V13.2804H74.1998V17.7823H77.4764Z"
        fill="#052A2E"
      />
      <path
        d="M81.7579 17.8498C82.9127 17.8498 83.7719 17.2153 84.0606 16.1894L83.0031 15.862C82.8536 16.4796 82.4118 16.8643 81.7579 16.8643C80.9822 16.8643 80.5231 16.294 80.5231 15.5313C80.5231 14.7686 80.9822 14.1949 81.7579 14.1949C82.4118 14.1949 82.8536 14.583 83.0031 15.2006L84.0606 14.8733C83.7719 13.8473 82.9127 13.2129 81.7579 13.2129C80.3318 13.2129 79.3926 14.2118 79.3926 15.5313C79.3926 16.8508 80.3318 17.8498 81.7579 17.8498Z"
        fill="#052A2E"
      />
      <path
        d="M86.9961 17.8498C88.4536 17.8498 89.424 16.8812 89.424 15.5313C89.424 14.1781 88.457 13.2129 86.9961 13.2129C85.5387 13.2129 84.5717 14.1781 84.5717 15.5313C84.5717 16.8812 85.5422 17.8498 86.9961 17.8498ZM86.9961 16.8643C86.1892 16.8643 85.7022 16.3008 85.7022 15.5313C85.7022 14.7585 86.1892 14.1949 86.9961 14.1949C87.8066 14.1949 88.2935 14.7585 88.2935 15.5313C88.2935 16.3008 87.8066 16.8643 86.9961 16.8643Z"
        fill="#052A2E"
      />
      <path
        d="M91.1283 17.7823V14.7011L92.6831 17.7823H94.0918V13.2804H93.0274V16.3615L91.4691 13.2804H90.0604V17.7823H91.1283Z"
        fill="#052A2E"
      />
      <path
        d="M96.7839 17.7823C98.2587 17.7823 99.2083 16.8508 99.2083 15.5347C99.2083 14.2118 98.2587 13.2804 96.7839 13.2804H94.8221V17.7823H96.7839ZM95.9073 14.2456H96.7839C97.5839 14.2456 98.0778 14.8193 98.0778 15.5347C98.0778 16.2434 97.5839 16.8137 96.7839 16.8137H95.9073V14.2456Z"
        fill="#052A2E"
      />
      <path
        d="M101.838 17.8498C103.173 17.8498 103.834 17.1546 103.834 15.9667V13.2804H102.749V15.8992C102.749 16.5437 102.506 16.8643 101.838 16.8643C101.17 16.8643 100.926 16.5437 100.926 15.8992V13.2804H99.8412V15.9667C99.8412 17.1546 100.502 17.8498 101.838 17.8498Z"
        fill="#052A2E"
      />
      <path
        d="M106.833 17.8498C107.987 17.8498 108.846 17.2153 109.135 16.1894L108.078 15.862C107.928 16.4796 107.486 16.8643 106.833 16.8643C106.057 16.8643 105.598 16.294 105.598 15.5313C105.598 14.7686 106.057 14.1949 106.833 14.1949C107.486 14.1949 107.928 14.583 108.078 15.2006L109.135 14.8733C108.846 13.8473 107.987 13.2129 106.833 13.2129C105.406 13.2129 104.467 14.2118 104.467 15.5313C104.467 16.8508 105.406 17.8498 106.833 17.8498Z"
        fill="#052A2E"
      />
      <path
        d="M111.959 17.7823V14.2287H113.379V13.2804H109.459V14.2287H110.878V17.7823H111.959Z"
        fill="#052A2E"
      />
      <path
        d="M119.966 17.7823L118.158 13.2804H116.926L115.128 17.7823H116.258L116.561 16.9892H118.509L118.811 17.7823H119.966ZM117.531 14.448L118.147 16.0477H116.923L117.531 14.448Z"
        fill="#052A2E"
      />
      <path
        d="M122.133 17.8498C123.469 17.8498 124.13 17.1546 124.13 15.9667V13.2804H123.044V15.8992C123.044 16.5437 122.801 16.8643 122.133 16.8643C121.465 16.8643 121.222 16.5437 121.222 15.8992V13.2804H120.137V15.9667C120.137 17.1546 120.797 17.8498 122.133 17.8498Z"
        fill="#052A2E"
      />
      <path
        d="M127.298 17.7823V14.2287H128.717V13.2804H124.797V14.2287H126.217V17.7823H127.298Z"
        fill="#052A2E"
      />
      <path
        d="M130.474 17.7823V15.9869H132.29V17.7823H133.375V13.2804H132.29V15.0184H130.474V13.2804H129.389V17.7823H130.474Z"
        fill="#052A2E"
      />
      <path
        d="M136.432 17.8498C137.89 17.8498 138.86 16.8812 138.86 15.5313C138.86 14.1781 137.893 13.2129 136.432 13.2129C134.975 13.2129 134.008 14.1781 134.008 15.5313C134.008 16.8812 134.978 17.8498 136.432 17.8498ZM136.432 16.8643C135.625 16.8643 135.138 16.3008 135.138 15.5313C135.138 14.7585 135.625 14.1949 136.432 14.1949C137.243 14.1949 137.73 14.7585 137.73 15.5313C137.73 16.3008 137.243 16.8643 136.432 16.8643Z"
        fill="#052A2E"
      />
      <path
        d="M142.234 16.0038C142.957 15.8452 143.364 15.3828 143.364 14.691C143.364 13.8136 142.71 13.2804 141.622 13.2804H139.496V17.7823H140.575V16.0645H141.058L142.363 17.7823H143.643L142.234 16.0038ZM141.611 14.2354C142.018 14.2354 142.244 14.3907 142.244 14.6944C142.244 14.9947 142.018 15.1534 141.611 15.1534H140.575V14.2354H141.611Z"
        fill="#052A2E"
      />
      <path
        d="M145.19 17.7823V13.2804H144.105V17.7823H145.19Z"
        fill="#052A2E"
      />
      <path
        d="M148.359 17.7823V14.2287H149.778V13.2804H145.858V14.2287H147.277V17.7823H148.359Z"
        fill="#052A2E"
      />
      <path
        d="M152.495 15.2141L151.385 13.2804H150.188L151.952 16.2569V17.7823H153.034V16.2569L154.797 13.2804H153.604L152.495 15.2141Z"
        fill="#052A2E"
      />
      <path
        d="M28.269 17.8498V14.1896H29.7713V13.2129H25.6216V14.1896H27.1239V17.8498H28.269Z"
        fill="#052A2E"
      />
      <path
        d="M31.4799 17.8498V16.0006H33.4019V17.8498H34.5507V13.2129H33.4019V15.003H31.4799V13.2129H30.331V17.8498H31.4799Z"
        fill="#052A2E"
      />
      <path
        d="M38.822 17.8498V16.88H36.3182V15.9797H38.6747V15.0099H36.3182V14.1827H38.822V13.2129H35.1693V17.8498H38.822Z"
        fill="#052A2E"
      />
    </svg>
  );
}
