import { AnimationEvent } from "chart.js";
import {
  DATASET_INDEX_EQUITY,
  EQUITY_CONFIG,
  MORTGAGE_BALANCE_CONFIG,
  PROPERTY_VALUE_CONFIG,
  TIMELINE_MONTHS,
} from "./consts";
import {
  scrollLatestMonthTooltipIntoView,
  tooltipHandler,
  triggerLatestMonthTooltipShow,
} from "./tooltips";
import { ChartDataType, DatasetDatapointsType } from "./types";

export const chartOptions = {
  responsive: true,
  pointHitRadius: 1,
  interaction: {
    intersect: false,
    mode: "point" as const,
  },
  events: ["click"],
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      position: "nearest" as const,
      enabled: false,
      external: tooltipHandler,
    },
  },
  layout: {
    padding: {
      left: 20,
      right: 20,
    },
  },
  scales: {
    x: {
      offset: true,
      display: true,
      ticks: {
        color: "#0D493B",
        font: {
          weight: "500",
          size: 16,
          family: "'Anek Latin', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
    y: {
      offset: true,
      display: false,
      suggestedMin: 0,
    },
  },
  animation: {
    onComplete: (animation: AnimationEvent) => {
      if (animation.initial) {
        setTimeout(() => {
          triggerLatestMonthTooltipShow(animation.chart, DATASET_INDEX_EQUITY);
        }, 250);
        setTimeout(() => {
          scrollLatestMonthTooltipIntoView();
        }, 1000);
      }
    },
  },
};

export const getYearDatasetData = (data: ChartDataType, year: number) => {
  if (!(year in data)) {
    return null;
  }

  const yearData = data[year];

  const dataPoints: DatasetDatapointsType = {
    propertyValue: [],
    mortgageBalance: [],
    equity: [],
  };

  for (const month in yearData) {
    dataPoints.propertyValue.push(yearData[month].propertyValue);
    dataPoints.mortgageBalance.push(yearData[month].mortgageBalance);
    dataPoints.equity.push(yearData[month].equity);
  }

  return dataPoints;
};

export const getChartDataFromQuery = (queryData: any) => {
  if (!queryData?.response?.timeline) {
    return null;
  }

  const currentYear = new Date().getFullYear();
  const datasets = getYearDatasetData(queryData.response.timeline, currentYear);

  if (!datasets) {
    return null;
  }

  return {
    labels: TIMELINE_MONTHS,
    datasets: [
      {
        ...PROPERTY_VALUE_CONFIG,
        data: datasets.propertyValue,
      },
      {
        ...MORTGAGE_BALANCE_CONFIG,
        data: datasets.mortgageBalance,
      },
      {
        ...EQUITY_CONFIG,
        data: datasets.equity,
      },
    ],
  };
};
