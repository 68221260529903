import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { trackPage } from "analytics";
import FormInputAddress from "components/Form/Input/Address";
import FormInputRadioGroup from "components/Form/Input/RadioGroup";
import FormInputText from "components/Form/Input/Text";
import ProgressBar from "components/GetStarted/ProgressBar";
import StepActions from "pages/GetStarted/components/StepActions";
import {
  BEDROOM_NUMBERS,
  FLAT_TYPES,
  HOUSE_TYPES,
  PROPERTY_TYPES,
  PROPERTY_TYPE_BUNGALOW,
  PROPERTY_TYPE_FLAT,
  PROPERTY_TYPE_HOUSE,
  STEPS,
} from "pages/GetStarted/consts";
import { getPropertySchema } from "pages/GetStarted/schema";
import { AddressType, FormInputsProperty } from "pages/GetStarted/types";
import { useGetStartedStore } from "store/GetStarted";
import { useUserStore } from "store/User";

export default function StepOne() {
  const [searchParams] = useSearchParams();

  const STEP_ID = 1;
  const STEP_KEY = STEPS[STEP_ID].key;

  const [manualAddress, setManualAddress] = useState<boolean>(false);
  const [isAddressLookup, setIsAddressLookup] = useState<boolean>(false);

  const { CAN_ADDRESS_LOOKUP, REQUIRE_PROPERTY_DETAILS, COPY } = useUserStore(
    (state) => state.userCountry
  );

  const submission = useGetStartedStore((state) => state.submission);
  const updateStepPercent = useGetStartedStore(
    (state) => state.updateStepPercent
  );
  const completeStep = useGetStartedStore((state) => state.completeStep);
  const updateSubmission = useGetStartedStore(
    (state) => state.updateSubmission
  );

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm<FormInputsProperty>({
    resolver: yupResolver(getPropertySchema(REQUIRE_PROPERTY_DETAILS)),
    defaultValues: submission.property,
  });

  const addressLookup = watch("address");
  const address = watch("addressLine1");
  const postcode = watch("addressPostcode");
  const propertyType = watch("propertyType");
  const buildingType = watch("buildingType");
  const bedroomCount = watch("bedroomCount");

  const fieldCompleteCount: number = [
    postcode,
    propertyType,
    buildingType,
    bedroomCount,
  ].filter((value) => {
    if (value === undefined) {
      return false;
    }
    if (typeof value === "string" && value.length === 0) {
      return false;
    }
    return true;
  }).length;

  const totalQuestions = 4;
  const stepPercent = (fieldCompleteCount / totalQuestions) * 100;

  useEffect(() => {
    trackPage("Get Started: Step 1");

    const address = searchParams.get("address");
    if (address) {
      setValue("address", address);
      setFocus("address");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateStepPercent(STEP_ID, stepPercent);
  }, [stepPercent, updateStepPercent]);

  useEffect(() => {
    setIsAddressLookup(CAN_ADDRESS_LOOKUP && !manualAddress);
  }, [CAN_ADDRESS_LOOKUP, manualAddress]);

  useEffect(() => {
    if (submission.property?.manualAddress) {
      setManualAddress(submission.property.manualAddress);
    }
  }, [submission?.property]);

  const resetFormFromManualAddress = (isManual: boolean) => {
    setManualAddress(isManual);
    setValue("address", "");
    setValue("addressBuildingName", "");
    setValue("addressBuildingNumber", "");
    setValue("addressSubBuildingName", "");
    setValue("addressSubBuildingNumber", "");
    setValue("addressThoroughfare", "");
    setValue("addressLine1", "");
    setValue("addressLine2", "");
    setValue("addressLine3", "");
    setValue("addressTownOrCity", "");
    setValue("addressCounty", "");
    setValue("addressPostcode", "");
    setValue("addressLatitude", "");
    setValue("addressLongitude", "");
    setValue("propertyType", "");
    setValue("buildingType", "");
    setValue("bedroomCount", "");
    clearErrors();
  };

  const setAddress = (address: AddressType) => {
    setValue("address", address?.address);
    setValue("addressBuildingName", address?.buildingName);
    setValue("addressBuildingNumber", address?.buildingNumber);
    setValue("addressSubBuildingName", address?.subBuildingName);
    setValue("addressSubBuildingNumber", address?.subBuildingNumber);
    setValue("addressThoroughfare", address?.thoroughfare);
    setValue("addressLine1", address?.line1);
    setValue("addressLine2", address.line2);
    setValue("addressLine3", address.line3);
    setValue("addressTownOrCity", address.townOrCity);
    setValue("addressCounty", address.county);
    setValue("addressPostcode", address.postcode);
    setValue("addressLatitude", address?.latitude);
    setValue("addressLongitude", address?.longitude);
    clearErrors("addressLine1");
  };

  const onSubmit = (data: any) => {
    updateSubmission(STEP_KEY, { ...data, manualAddress: manualAddress });
    completeStep(STEP_ID);
    navigate("./account");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ProgressBar />

        <div className="mt-6 grid grid-cols-6 gap-4 sm:mt-8 sm:gap-6">
          {isAddressLookup && (
            <div className="col-span-6">
              <FormInputAddress
                id="address"
                label="What's your home address?"
                initialAddress={addressLookup}
                register={register}
                setValue={setAddress}
                triggerManualInput={() => resetFormFromManualAddress(true)}
                error={
                  errors?.addressLine1?.message
                    ? "Please enter a valid address"
                    : ""
                }
              />
            </div>
          )}
        </div>

        {!isAddressLookup && (
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-6 sm:col-span-4">
              <FormInputText
                id="addressLine1"
                label={COPY.ADDRESS_LABELS.LINE_1}
                register={register}
                error={errors?.addressLine1?.message}
              />
            </div>
            {CAN_ADDRESS_LOOKUP && (
              <div className="order-first col-span-6 text-right">
                <button
                  onClick={() => resetFormFromManualAddress(false)}
                  className="link pr-0 text-sm underline sm:pr-5"
                  type="button"
                >
                  Try an address look up again?
                </button>
              </div>
            )}
            <div className="col-span-6 sm:col-span-4">
              <FormInputText
                id="addressLine2"
                label={COPY.ADDRESS_LABELS.LINE_2}
                register={register}
                error={errors?.addressLine2?.message}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <FormInputText
                id="addressLine3"
                label={COPY.ADDRESS_LABELS.LINE_3}
                register={register}
                error={errors?.addressLine3?.message}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <FormInputText
                id="addressTownOrCity"
                label={COPY.ADDRESS_LABELS.TOWN_CITY}
                register={register}
                error={errors?.addressTownOrCity?.message}
              />
            </div>
            <div className="col-span-6 sm:col-span-4">
              <FormInputText
                id="addressCounty"
                label={COPY.ADDRESS_LABELS.COUNTY}
                register={register}
                error={errors?.addressCounty?.message}
              />
            </div>
            {COPY.ADDRESS_LABELS?.POSTCODE && (
              <div className="col-span-6 sm:col-span-3">
                <FormInputText
                  id="addressPostcode"
                  label="Postcode"
                  register={register}
                  error={errors?.addressPostcode?.message}
                />
              </div>
            )}
          </div>
        )}

        <div className="mt-4 grid grid-cols-6 gap-4 sm:mt-6 sm:gap-6">
          {address && postcode && (
            <>
              <div className="col-span-6">
                <FormInputRadioGroup
                  id="propertyType"
                  label="What type of building is your property?"
                  options={PROPERTY_TYPES}
                  error={errors?.propertyType?.message}
                  control={control}
                />
              </div>

              {propertyType && (
                <div className="col-span-6">
                  {propertyType === PROPERTY_TYPE_HOUSE && (
                    <FormInputRadioGroup
                      id="buildingType"
                      label="What kind of house is it?"
                      options={HOUSE_TYPES}
                      error={errors?.buildingType?.message}
                      control={control}
                    />
                  )}
                  {propertyType === PROPERTY_TYPE_FLAT && (
                    <FormInputRadioGroup
                      id="buildingType"
                      label="What kind of flat is it?"
                      options={FLAT_TYPES}
                      error={errors?.buildingType?.message}
                      control={control}
                    />
                  )}
                  {propertyType === PROPERTY_TYPE_BUNGALOW && (
                    <FormInputRadioGroup
                      id="buildingType"
                      label="What kind of bungalow is it?"
                      options={HOUSE_TYPES}
                      error={errors?.buildingType?.message}
                      control={control}
                    />
                  )}
                </div>
              )}

              {propertyType && buildingType && (
                <div className="col-span-6">
                  <FormInputRadioGroup
                    id="bedroomCount"
                    label="How many bedrooms does the property have?"
                    options={BEDROOM_NUMBERS}
                    error={errors?.bedroomCount?.message}
                    control={control}
                  />
                </div>
              )}
            </>
          )}
        </div>

        <StepActions />
      </form>
    </>
  );
}
