import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Bars3BottomRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";

import BookACall from "components/BookACall";
import LogoWhite from "components/Image/Logo/White";
import { NavigationOptionsType } from "components/Navigation/types";
import { APP_URLS } from "settings";
import { useUserStore } from "store/User";
import IconTimeline from "components/Image/Icons/Timeline";

export default function NavigationDesktop({
  navOpen,
  toggleNav,
}: NavigationOptionsType) {
  const user = useUserStore((state) => state.user);
  const eligibleForCall = useUserStore((state) => state.eligibleForCall);
  const eligibleForLead = useUserStore((state) => state.eligibleForLead);
  const equityCheckIsSuccess = useUserStore(
    (state) => state.equityCheckIsSuccess
  );

  const navigate = useNavigate();

  return (
    <div className="relative z-10 mx-auto 2xl:container sm:px-6 lg:px-8">
      <div className="flex h-20 items-center justify-between px-4 sm:px-0">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <a href={APP_URLS.DASHBOARD}>
              <LogoWhite />
            </a>
          </div>
        </div>
        <div className="flex space-x-4 md:space-x-6">
          {equityCheckIsSuccess && (
            <button
              type="button"
              onClick={() => navigate(APP_URLS.EQUITY_TIMELINE)}
            >
              <span className="flex items-center pr-1">
                <IconTimeline />
                <span className="hidden text-lg text-white hover:text-brand-green-bright md:ml-2 md:block">
                  Timeline
                </span>
              </span>
            </button>
          )}
          {eligibleForCall && eligibleForLead && (
            <BookACall>
              <span className="flex items-center text-lg text-white hover:text-brand-green-bright">
                <ChatBubbleOvalLeftEllipsisIcon className="h-9 w-9 md:mr-2" />
                <span className="hidden md:block">Book a call</span>
              </span>
            </BookACall>
          )}
          {user && user.isVerified && (
            <button
              type="button"
              className="text-white hover:text-brand-green-bright"
              onClick={() => navigate(APP_URLS.ACCOUNT.ACCOUNT_PROFILE)}
            >
              <UserCircleIcon className="h-9 w-9" />
            </button>
          )}
          <button
            onClick={() => toggleNav()}
            className="inline-flex items-center justify-center text-white hover:text-brand-green-bright"
          >
            <span className="sr-only">Open main menu</span>
            {navOpen ? (
              <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
            ) : (
              <Bars3BottomRightIcon className="block h-8 w-8" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
