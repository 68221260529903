import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { useUserStore } from "store/User";

export type ModalProps = {
  title?: string;
  children?: any;
  onClose?: Function;
};

export default function CRMDialog({ title, children, onClose }: ModalProps) {
  const setAppointmentTrigger = useUserStore(
    (state) => state.setAppointmentTrigger
  );

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setAppointmentTrigger();
    onClose && onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end items-center justify-center p-2 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-[345px] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <Dialog.Title className="relative z-10 flex justify-between bg-brand-green-medium p-4 text-white">
                  {title}
                  <XMarkIcon
                    className="h-6 w-6 cursor-pointer"
                    onClick={handleClose}
                  />
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
