import { Fragment, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation } from "@apollo/client";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";

import { MUTATION_CREATE_LEADGEN } from "api/mutations/leads";

import CTAButton from "components/CTA/Button";
import {
  ENQUIRY_EQUITY_RELEASE,
  ENQUIRY_PRODUCT_OPTIONS,
} from "components/BookACall/consts";
import schema from "components/BookACall/schema";
import { BookACallFormInputs } from "components/BookACall/types";
import FormInputText from "components/Form/Input/Text";
import FormInputRadioGroup from "components/Form/Input/RadioGroup";
import Loading from "components/Loading";
import { useUserStore } from "store/User";
import AlertError from "components/Alert/Error";
import { AHAUZ_PHONE_NUMBER } from "settings";

const DEFAULT_SUBMITTED_PRODUCT = "N/A";

export default function BookingForm({
  open,
  handleClose,
  trackBookingSuccess,
  phoneNumber,
  product,
  title,
}: {
  open: boolean;
  handleClose: Function;
  trackBookingSuccess: Function;
  phoneNumber?: string;
  product?: string;
  title?: string;
}) {
  const [submitted, setSubmitted] = useState(false);
  const [isInternalCall, setIsInternalCall] = useState(true);
  const [loading, setLoading] = useState(false);

  const equityCheck = useUserStore((state) => state.equityCheck);
  const user = useUserStore((state) => state.user);

  const updateUserPhoneNumber = useUserStore(
    (state) => state.updateUserPhoneNumber
  );

  const updateCallWindowOpen = useUserStore(
    (state) => state.updateCallWindowOpen
  );

  const enquiryOptions =
    user?.detail?.age && user?.detail?.age < 55
      ? [...ENQUIRY_PRODUCT_OPTIONS].filter(
          (obj) => obj.value !== ENQUIRY_EQUITY_RELEASE
        )
      : [...ENQUIRY_PRODUCT_OPTIONS];

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm<BookACallFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: phoneNumber,
      product: product,
    },
  });

  const [
    leadgenCreate,
    {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      reset: resetMutation,
    },
  ] = useMutation(MUTATION_CREATE_LEADGEN);

  useEffect(() => {
    setValue("phoneNumber", phoneNumber || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (mutationData?.response?.ok) {
      const _submittedProduct =
        mutationData?.response?.leadgen?.product || DEFAULT_SUBMITTED_PRODUCT;
      const _isInternalCall = mutationData?.response?.leadgen?.isInternal;

      setIsInternalCall(_isInternalCall);

      if (!phoneNumber) {
        updateUserPhoneNumber(
          mutationData.response.customer.detail.phoneNumber
        );
      }

      updateCallWindowOpen(mutationData.response.customer.callWindowOpen);

      setTimeout(() => {
        setSubmitted(true);
        setLoading(false);
        trackBookingSuccess(_submittedProduct);
        setTimeout(() => {
          resetModal();
        }, 5000);
      }, 1000);
    }
    if (!mutationData?.response?.ok || mutationError) {
      if (mutationData?.response?.errors) {
        const serverErrors: BookACallFormInputs = mutationData.response.errors;

        for (let [key, errors] of Object.entries(serverErrors)) {
          setError(key as keyof BookACallFormInputs, {
            type: "server",
            message: errors,
          });
        }
      }

      setTimeout(() => {
        setSubmitted(false);
        setLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData, mutationError]);

  const resetModal = () => {
    handleClose();
    setTimeout(() => {
      reset();
      resetMutation();
      setSubmitted(false);
      setIsInternalCall(true);
      setLoading(false);
    }, 550);
  };

  const closeForm = () => {
    resetModal();
  };

  const onSubmit: SubmitHandler<BookACallFormInputs> = (data) => {
    setLoading(true);

    leadgenCreate({
      variables: {
        input: {
          equityCheck: equityCheck?.id,
          phoneNumber: data.phoneNumber,
          product: data.product,
        },
      },
    });
  };

  const renderSubmitted = () => (
    <div className="mt-2">
      {isInternalCall ? (
        <>
          <p className="text-base text-gray-500">Enquiry received!</p>
          <p className="mt-2 text-base text-gray-500">
            You will receive a call shortly.
          </p>
        </>
      ) : (
        <>
          <p className="text-base text-gray-500">
            Enquiry received! Our adviser partner
            {mutationData?.response?.leadgen?.advisorName
              ? ` (${mutationData.response.leadgen.advisorName}) `
              : " "}
            will call you as soon as possible.
          </p>
          <p className="mt-2 text-base text-gray-500">
            We'll email you their details.
          </p>
        </>
      )}
      <div className="mt-5 sm:flex sm:justify-end sm:pl-4">
        <div className="sm:ml-3">
          <CTAButton full label="Back to My Dashboard" onClick={closeForm} />
        </div>
      </div>
    </div>
  );

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="mb-5 mt-4 sm:mb-4">
        <FormInputText
          id="phoneNumber"
          label="Please confirm your phone number"
          register={register}
          error={errors?.phoneNumber?.message}
        />
      </div>
      {!product && (
        <div className="mb-5 mt-4 sm:mb-4">
          <FormInputRadioGroup
            id="product"
            label="I'd like to speak about"
            options={enquiryOptions}
            error={errors?.product?.message}
            control={control}
            fiftyfifty
          />
        </div>
      )}
      <div className="mb-5 mt-4 sm:mb-4">
        <p className="mt-2 text-sm italic text-gray-500">
          By clicking confirm you consent to us sharing your data with our
          trusted broker partner
        </p>
      </div>
      {mutationError && (
        <div className="mb-5 mt-4 sm:mb-4">
          <AlertError title="Can not book a call">
            <p>{`Sorry, we can't arrange your time to speak to an Advisor right now. Please try again or contact our Customer Support team on ${AHAUZ_PHONE_NUMBER}.`}</p>
          </AlertError>
        </div>
      )}
      <div className="sm:ml-10 sm:flex sm:justify-end sm:pl-4">
        <div className="sm:ml-3">
          <CTAButton full type="submit" label="Confirm" />
        </div>
        <div className="order-first mt-3 sm:mt-0">
          <CTAButton full secondary label="Cancel" onClick={closeForm} />
        </div>
      </div>
    </form>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeForm}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform self-center overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-orange-medium sm:mx-0 sm:h-10 sm:w-10">
                    <ChatBubbleOvalLeftEllipsisIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-brand-green-dark"
                    >
                      {title}
                    </Dialog.Title>
                    {loading || mutationLoading ? (
                      <div className="w-full px-24 py-6">
                        <Loading />
                      </div>
                    ) : submitted || !user?.callWindowOpen ? (
                      renderSubmitted()
                    ) : (
                      renderForm()
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
