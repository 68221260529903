import { Switch } from "@headlessui/react";
import { Controller } from "react-hook-form";
import { CheckIcon } from "@heroicons/react/24/outline";
import { classNames } from "utils/helpers";

function Checkbox({
  id,
  label,
  checked,
  onChange,
  small,
}: {
  id: string;
  label: string;
  checked: boolean;
  onChange?: any;
  small?: boolean;
}) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch
          checked={checked}
          onChange={onChange}
          name={id}
          className="relative flex h-9 w-9 shrink-0 items-center justify-center rounded-full border border-2 border-brand-green-dark bg-white outline-none"
        >
          {checked ? (
            <CheckIcon className="h-6 w-6" />
          ) : (
            <span className="h-9 w-9 "></span>
          )}
        </Switch>
        <Switch.Label
          className={classNames(
            small ? "text-sm" : "text-base",
            "ml-2 ml-4 cursor-pointer"
          )}
        >
          {label}
        </Switch.Label>
      </div>
    </Switch.Group>
  );
}

export default function FormInputCheckbox({
  id,
  label,
  onChange,
  control,
  checked = false,
  small = false,
}: {
  id: string;
  label: string;
  checked?: boolean;
  onChange?: any;
  control?: any;
  small?: boolean;
}) {
  if (control) {
    return (
      <Controller
        control={control}
        defaultValue=""
        name={id}
        render={({ field }) => (
          <Checkbox
            id={id}
            label={label}
            checked={field.value}
            onChange={field.onChange}
            small={small}
          />
        )}
      />
    );
  }

  return (
    <Checkbox id={id} label={label} checked={checked} onChange={onChange} />
  );
}
