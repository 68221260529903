export default function ActionCTATitle({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) {
  return (
    <div className="flex items-center">
      <span className="z-10 inline flex h-12 w-12 items-center justify-center rounded-full bg-brand-green-medium text-3xl shadow-[0px_0px_0px_8px_rgba(127,246,215,0.3)]">
        {children}
      </span>
      <h3 className="-ml-5 h-fit rounded-lg bg-brand-green-dark px-3 py-2 pl-10 pr-5 text-sm font-semibold uppercase text-white">
        {title}
      </h3>
    </div>
  );
}
