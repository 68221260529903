import { gql } from "@apollo/client";

import { FRAGMENT_BASE_RESPONSE_FIELDS } from "api/fragments";

export const MUTATION_CREATE_LEADGEN = gql`
  mutation CreateLeadgen($input: LeadGenInput!) {
    response: leadgenCreate(input: $input) {
      ...BaseResponseFields
      customer {
        detail {
          phoneNumber
        }
      }
      leadgen {
        product
        isInternal
        advisorName
      }
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;
