import { useState } from "react";

import { trackEvent } from "analytics";
import CRMNewAppointment from "components/CRM/NewAppointment";
import CTAButton from "components/CTA/Button";
import BookingForm from "components/BookACall/BookingForm";
import { useUserStore } from "store/User";
import { ANALYTICS } from "settings";

export default function BookACall({
  bookingLink,
  customAction,
  product,
  children,
  title = "Speak to an Advisor",
}: {
  bookingLink?: string;
  customAction?: Function;
  product?: string;
  title?: string;
  children?: JSX.Element;
}) {
  const [open, setOpen] = useState(false);
  const user = useUserStore((state) => state.user);
  const eligibleForLead = useUserStore((state) => state.eligibleForLead);

  const openModal = () => {
    customAction && customAction();
    setOpen(true);
    user?.callWindowOpen &&
      trackEvent(`Partner Call: Open: ${product || "N/A"}`);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const trackBookingSuccess = (product: string) => {
    trackEvent(`Partner Call: Booked: ${product}`);

    if (ANALYTICS.GA.CALL_CONVERSION && window.gtag) {
      window.gtag("event", "conversion", {
        send_to: ANALYTICS.GA.CALL_CONVERSION,
      });
    }
  };

  const renderCTA = (onClick: Function | null = null) =>
    children ? (
      <button onClick={() => (onClick ? onClick() : undefined)}>
        {children}
      </button>
    ) : (
      <CTAButton
        label="Speak to an Advisor >"
        styling="text-black border-none bg-brand-orange-medium hover:bg-brand-orange-bright"
        onClick={() => (onClick ? onClick() : undefined)}
      />
    );

  if (!eligibleForLead) {
    return (
      <CRMNewAppointment bookingLink={bookingLink} customAction={customAction}>
        {renderCTA()}
      </CRMNewAppointment>
    );
  }

  return (
    <>
      {renderCTA(() => openModal())}
      <BookingForm
        open={open}
        handleClose={closeModal}
        trackBookingSuccess={trackBookingSuccess}
        phoneNumber={user?.detail?.phoneNumber}
        product={product}
        title={title}
      />
    </>
  );
}
