import { FlagIcon } from "@heroicons/react/24/solid";
import { useGetStartedStore } from "store/GetStarted";
import { useUserStore } from "store/User";
import { classNames } from "utils/helpers";

export default function ProgressBar() {
  const steps = useGetStartedStore((state) => state.steps);
  const { SHOW_SIGN_UP_PROGRESS } = useUserStore((state) => state.userCountry);

  if (!SHOW_SIGN_UP_PROGRESS) {
    return <div className="-mt-6"></div>;
  }

  return (
    <section>
      <div className="flex">
        {steps.map((step, stepIdx, stepArray) => (
          <div key={step.name} className="w-1/3">
            <div className="relative mb-2">
              {step.number > 1 && (
                <div
                  className="align-center absolute flex content-center items-center align-middle"
                  style={{
                    width: "calc(100% - 2.5rem - 1rem)",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div className="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                    <div
                      className="transition-slowest ease w-0 rounded bg-brand-orange-medium py-0.5 transition-width"
                      style={{ width: `${stepArray[stepIdx - 1].progress}%` }}
                    ></div>
                  </div>
                </div>
              )}

              <div
                className={classNames(
                  (step.status === "complete" &&
                    step.number !== steps.length) ||
                    (step.status === "current" && step.number !== steps.length)
                    ? "bg-brand-orange-medium"
                    : "bg-gray-200",
                  "mx-auto flex h-8 w-8 items-center rounded-full text-lg text-white"
                )}
              >
                {step.number === steps.length ? (
                  <div
                    className={classNames(
                      step.status === "complete"
                        ? "bg-brand-green-medium"
                        : "bg-gray-200",
                      "mx-auto flex h-8 w-8 items-center rounded-full text-lg"
                    )}
                  >
                    <FlagIcon
                      className={classNames(
                        step.status === "complete"
                          ? "text-white"
                          : step.status === "fail"
                          ? "text-red-600"
                          : "text-gray-400",
                        "mx-auto h-4 w-4"
                      )}
                    />
                  </div>
                ) : (
                  <span
                    className={classNames(
                      step.status === "complete" || step.status === "current"
                        ? "text-white"
                        : "text-gray-400",
                      "w-full text-center"
                    )}
                  >
                    {step.number}
                  </span>
                )}
              </div>
            </div>

            <div className="text-center text-xs">
              <span className="font-medium">{step.name}</span>
              <br />
              {step.duration && <span>{step.duration} min</span>}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
