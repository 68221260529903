import { useEffect } from "react";
import { LEADS } from "settings";

const useLeadGens = (init: boolean = false) => {
  useEffect(() => {
    if (init) {
      // Contact State
      window["ContactStateCertify"] = "cscertify";
      window["cscertify"] =
        window["cscertify"] ||
        function () {
          (window["cscertify"].q = window["cscertify"].q || []).push(arguments);
        };
      window["cscertify"]("init", {
        landing_page_id: LEADS.CONTACT_STATE.LANDING_PAGE_ID,
      });

      const firstScipt = document.getElementsByTagName("script")[0];
      const script = document.createElement("script");
      script.id = "cscertify";
      script.src = LEADS.CONTACT_STATE.CERTIFY_URL;
      script.async = true;
      firstScipt?.parentNode?.insertBefore(script, firstScipt);

      return () => {
        script.remove();
      };
    }
  }, [init]);
};

export default useLeadGens;
