import { useMutation, useQuery } from "@apollo/client";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";

import { MUTATION_CREATE_CUSTOMER_ACCOUNT } from "api/mutations/account";
import { QUERY_ACCOUNT_VALIDATE_INVITE_TOKEN } from "api/queries/account";
import { APP_URLS } from "settings";

import AlertSuccess from "components/Alert/Success";
import AccountFormSetPassword from "pages/Account/components/Form/SetPassword";

import AuthScreenBase from "components/Auth/AuthScreenBase";
import * as consts from "pages/Account/AcceptInvite/consts";

export default function AccountAcceptInvite() {
  let params = useParams();
  let token = params.token;

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(QUERY_ACCOUNT_VALIDATE_INVITE_TOKEN, {
    variables: {
      token: token,
    },
  });

  const [createCustomerAccount, mutationState] = useMutation(
    MUTATION_CREATE_CUSTOMER_ACCOUNT
  );

  const handleFormSubmit = (formData: any) => {
    createCustomerAccount({
      variables: {
        account: {
          token: token,
          newPassword1: formData.newPassword1,
          newPassword2: formData.newPassword2,
        },
      },
    });
  };

  /**
   * Render success message when account has been created
   * @returns ReactElement
   */
  const renderCreateAccountSuccess = (): ReactElement => (
    <AlertSuccess title={consts.COPY_ACCOUNT_CREATED_TITLE} shadow>
      <p>
        Your account has been set up and we'll now redirect you to your{" "}
        <a
          href={APP_URLS.DASHBOARD}
          className="text-green-800 underline hover:text-green-800"
        >
          Dashboard
        </a>
        .
      </p>
    </AlertSuccess>
  );

  /**
   * Determine which component to render based on API query result
   * @returns ReactElement
   */
  const renderAcceptInvite = () => (
    <AccountFormSetPassword
      loading={queryLoading}
      error={queryError}
      errorMessage={consts.COPY_INVALID_INVITE_URL}
      ok={queryData && queryData?.accountValidateInviteToken?.ok}
      onSubmit={handleFormSubmit}
      onSuccess={renderCreateAccountSuccess}
      mutationState={mutationState}
      submitCTA={consts.COPY_CREATE_ACCOUNT_SUBMIT}
    />
  );

  return (
    <AuthScreenBase
      title={consts.COPY_CREATE_ACCOUNT_TITLE}
      subtitle="Please submit your registered email address, we'll send you an email to reset your password."
      signup
    >
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="px-2 sm:mx-auto sm:w-full sm:max-w-md sm:px-0">
          {renderAcceptInvite()}
          <div className="mt-8 text-center text-base text-brand-copy-light">
            Already have an account?{" "}
            <a href={APP_URLS.LOGIN}>Click here to log in</a>
          </div>
        </div>
      </div>
    </AuthScreenBase>
  );
}
