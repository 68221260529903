import { useEffect, useState } from "react";
import moment from "moment";
import { FormInputProps } from "components/Form/Input/types";
import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputLabel from "components/Form/Input/Label";
import FormInputError from "components/Form/Input/Error";
import { DOB_INVALID } from "pages/GetStarted/schema";

type FormInputSplitDateProps = FormInputProps & {
  setValue: Function;
};

export default function FormInputSplitDate({
  id,
  label,
  error,
  register,
  setValue,
}: FormInputSplitDateProps) {
  const [dateParts, setDatePart] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [isValidDate, setIsValidDate] = useState(true);

  useEffect(() => {
    setIsValidDate(true);

    if (dateParts.year && dateParts.month && dateParts.day) {
      const validDate = moment
        .utc(
          `${dateParts.day}/${dateParts.month}/${dateParts.year}`,
          "DD/MM/YYYY",
          "en"
        )
        .format("YYYY-MM-DD");

      if (validDate) {
        setValue(id, validDate);
      } else {
        setIsValidDate(false);
      }
    }
  }, [dateParts, id, setValue]);

  if (!register) {
    return <></>;
  }

  const handleDatePartInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxNumInput = Number(event.target.dataset.maxnums) || 0;
    const datePartType = event.target.dataset.parttype || "";
    const value = event.target.value;

    setDatePart({
      ...dateParts,
      ...{
        [datePartType]: value.slice(0, maxNumInput),
      },
    });
  };

  const hasErrors = error.root || error.day || error.month || error.year;

  const renderErrors = () => {
    if (!isValidDate) {
      return <FormInputError error={DOB_INVALID} />;
    }
    if (hasErrors) {
      return <FormInputError error={hasErrors} />;
    }
    return <></>;
  };

  return (
    <>
      <FormInputLabel label={label} />

      <div>
        <div className="flex flex-row">
          <div className="basis-3/12">
            <FormInputLabel id={`${id}PartDay`} label="Day" light />
            <div className={STYLE_INPUT_MARGIN_TOP}>
              <input
                type="string"
                id={`${id}PartDay`}
                required
                value={dateParts.day}
                {...register(`${id}PartDay`, {
                  required: true,
                  onChange: handleDatePartInput,
                })}
                autoComplete="bday-day"
                inputMode="numeric"
                data-parttype="day"
                data-maxnums={2}
                className={STYLE_INPUT}
                placeholder="DD"
              />
            </div>
          </div>

          <div className="ml-3 basis-3/12">
            <FormInputLabel id={`${id}PartMonth`} label="Month" light />
            <div className={STYLE_INPUT_MARGIN_TOP}>
              <input
                type="string"
                id={`${id}PartMonth`}
                required
                value={dateParts.month}
                {...register(`${id}PartMonth`, {
                  required: true,
                  onChange: handleDatePartInput,
                })}
                autoComplete="bday-month"
                inputMode="numeric"
                data-parttype="month"
                data-maxnums={2}
                className={STYLE_INPUT}
                placeholder="MM"
              />
            </div>
          </div>

          <div className="ml-3 basis-6/12">
            <FormInputLabel id={`${id}PartYear`} label="Year" light />
            <div className={STYLE_INPUT_MARGIN_TOP}>
              <input
                type="string"
                id={`${id}PartYear`}
                required
                value={dateParts.year}
                {...register(`${id}PartYear`, {
                  required: true,
                  onChange: handleDatePartInput,
                })}
                autoComplete="bday-year"
                inputMode="numeric"
                data-parttype="year"
                data-maxnums={4}
                className={STYLE_INPUT}
                placeholder="YYYY"
              />
            </div>
          </div>
        </div>
      </div>
      {renderErrors()}
    </>
  );
}
