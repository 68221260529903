import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Navigate, useLocation } from "react-router-dom";

import { trackPage } from "analytics";
import { QUERY_AUTHENTICATED_USER } from "api/queries/account";
import Loading from "components/Loading";
import ProgressBar from "components/GetStarted/ProgressBar";
import { ANALYTICS, APP_URLS, COOKIES } from "settings";
import { deleteCookie } from "utils/helpers";

export default function StepComplete() {
  const location = useLocation();
  const submission = location.state?.submission;

  // Only handle a successful submission if the user has completed the form
  if (!submission) {
    return <StepCompleteInvalid />;
  }
  return <StepCompleteHandler />;
}

function StepCompleteInvalid() {
  // Navigate to Get Started root if no submission
  return <Navigate to={APP_URLS.GET_STARTED.ROOT} replace />;
}

function StepCompleteHandler() {
  const DASHBOARD_REDIRECT_TIMEOUT = 30000; // 30 seconds

  const { data: queryData } = useQuery(QUERY_AUTHENTICATED_USER, {
    fetchPolicy: "no-cache",
    pollInterval: 5000, // Poll every 5 seconds
  });

  /**
   * Setup the default redirect timeout
   */
  useEffect(() => {
    trackPage("Get Started: Complete");
    deleteCookie(COOKIES.SIGNUP_LANDING_PAGE);

    if (ANALYTICS.GA.LEAD_CONVERSION && window.gtag) {
      window.gtag("event", "customer_signup");
      window.gtag("event", "conversion", {
        send_to: ANALYTICS.GA.LEAD_CONVERSION,
        form: "Home Equity Tracker",
      });
    }

    setTimeout(() => {
      window.location.href = APP_URLS.DASHBOARD;
    }, DASHBOARD_REDIRECT_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * When we have a valid submission with data, dont wait for the default timout
   */
  useEffect(() => {
    const customer = queryData?.customer;
    if (customer?.verificationCheck || customer?.verificationFail) {
      window.location.href = APP_URLS.ACCOUNT.ACCOUNT_VERIFICATION;
    }
    if (customer?.equityCheck?.live) {
      window.location.href = APP_URLS.DASHBOARD;
    }
  }, [queryData]);

  return (
    <div className="complete mx-auto w-full space-y-10 text-center ">
      <ProgressBar />

      <div className="text-lg">
        <Loading large />

        <p className="mb-2 mt-6">
          We're just building your dashboard and working out your home equity
          position.
        </p>
        <p>You'll be redirected shortly!</p>
      </div>
    </div>
  );
}
