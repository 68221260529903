type Props = {
  onClick?: Function;
};

export default function LogoIconDark({ onClick }: Props) {
  const handleOnClick = () => onClick && onClick();

  return (
    <svg
      width="55"
      height="45"
      viewBox="0 0 55 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`mx-auto h-12 w-auto${onClick && " cursor-pointer"}`}
      onClick={handleOnClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.47 0L0 18.308H8.68067V44.5871L27.47 4.8621L46.2593 44.5871V18.308H54.9385L27.47 0Z"
        fill="#052A2E"
      />
    </svg>
  );
}
