import { useNotificationStore } from "store/Notifications";
import Notification from "components/Notifications/Notification";

export default function Notifications() {
  const notifications = useNotificationStore((state) => state.notifications);
  const dismissNotification = useNotificationStore(
    (state) => state.dismissNotification
  );

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-50 flex items-end px-2 py-2 sm:items-start sm:p-6 sm:px-4 sm:py-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {notifications.map((notification: any, i) => (
          <Notification
            key={i}
            notification={notification}
            onDismiss={dismissNotification}
          />
        ))}
      </div>
    </div>
  );
}
