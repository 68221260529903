import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { MUTATION_VERIFY_CUSTOMER_ACCOUNT } from "api/mutations/account";
import Error from "components/Alert/Error";
import AuthScreenBase from "components/Auth/AuthScreenBase";
import ProgressBar from "components/GetStarted/ProgressBar";
import Failed from "pages/Account/AccountVerification/components/Failed";
import LastMortgageYear from "pages/Account/AccountVerification/components/LastMortgageYear";
import Mortgage from "pages/Account/AccountVerification/components/Mortgage";
import Success from "pages/Account/AccountVerification/components/Success";
import {
  MortgageQaOptionsType,
  MortgageQaType,
} from "pages/Account/AccountVerification/types";
import { GET_STARTED_COPY } from "pages/GetStarted/consts";
import { APP_URLS } from "settings";
import { useGetStartedStore } from "store/GetStarted";
import { useUserStore } from "store/User";

export default function AccountVerification() {
  const [inlineMortgageQa, setInlineMortgageQa] =
    useState<MortgageQaType | null>(null);
  const [showMessage, setShowMessage] = useState(false);
  const user = useUserStore((state) => state.user);
  const completeStep = useGetStartedStore((state) => state.completeStep);

  const STEP_ID = 3;
  const verificationCheck = user?.verificationCheck;
  const verificationFail = user?.verificationFail;

  const [
    verifyCustomer,
    { loading: verifyLoading, error: verifyError, data: verifyData },
  ] = useMutation(MUTATION_VERIFY_CUSTOMER_ACCOUNT);

  useEffect(() => {
    completeStep(1);
    completeStep(2);
    if (verificationFail) {
      completeStep(STEP_ID, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationFail]);

  useEffect(() => {
    if (verifyData?.response?.mortgageQa) {
      setInlineMortgageQa(verifyData.response.mortgageQa);
    } else if (verifyData?.response?.ok || verifyData?.response?.manual) {
      if (verifyData?.response?.ok) {
        completeStep(STEP_ID);
        setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 2000);
      } else {
        completeStep(STEP_ID, true);
      }
      setShowMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  if (!verificationCheck && !verificationFail) {
    return <Navigate to={APP_URLS.DASHBOARD} />;
  }

  const lastMortgageYearQa = verificationCheck?.lastMortgageYearQa;
  const mortgageQa = inlineMortgageQa || verificationCheck?.mortgageQa;
  const error = verifyError;

  const errorBlock = () => {
    if (!error) {
      return null;
    }

    return (
      <div className="mb-3">
        <Error>
          <>{error}</>
        </Error>
      </div>
    );
  };

  const handleMortgageYearSubmit = (lastMortgageYearAnswer: string) => {
    verifyCustomer({
      variables: {
        lastMortgageYearInput: { answer: lastMortgageYearAnswer },
      },
    });
  };

  const handleMortgageSubmit = (mortgageAnswer: MortgageQaOptionsType[]) => {
    verifyCustomer({
      variables: {
        mortgageInput: mortgageAnswer.map((ma: any) => {
          return {
            balanceAmount: ma.balanceAmount,
            startedAt: ma.startedAt,
          };
        }),
      },
    });
  };

  const messageBlock = () =>
    verifyData?.response?.ok ? <Success /> : <Failed />;

  const contentBlock = () => {
    return (
      <>
        <LastMortgageYear
          lastMortgageYearQa={lastMortgageYearQa}
          mortgageQa={mortgageQa}
          error={errorBlock}
          loading={verifyLoading}
          handleMortgageYearSubmit={handleMortgageYearSubmit}
        />
        <Mortgage
          mortgageQa={mortgageQa}
          error={errorBlock}
          loading={verifyLoading}
          handleMortgageSubmit={handleMortgageSubmit}
        />
      </>
    );
  };

  return (
    <AuthScreenBase
      title={GET_STARTED_COPY.title}
      subtitle={GET_STARTED_COPY.subtitle}
    >
      <>
        <ProgressBar />
        {verificationFail ? (
          <Failed />
        ) : showMessage ? (
          messageBlock()
        ) : (
          contentBlock()
        )}
      </>
    </AuthScreenBase>
  );
}
