import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import AuthScreenBase from "components/Auth/AuthScreenBase";
import CTAButton from "components/CTA/Button";
import FormInputEmail from "components/Form/Input/Email";
import { VALIDATION_EMAIL } from "utils/validation";
import { COPY_LOGIN_TITLE } from "../consts";
import { EmailOnlyFormInputs } from "../index.types";

const schema = yup.object({
  email: yup.string().email(VALIDATION_EMAIL).required(VALIDATION_EMAIL),
});

export default function EmailOnlyLogin({
  submitEmailOnlyLogin,
}: {
  submitEmailOnlyLogin: Function;
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EmailOnlyFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  let input_email = watch("email");

  const onSubmit = (data: EmailOnlyFormInputs) => {
    submitEmailOnlyLogin(data.email);
  };

  return (
    <AuthScreenBase
      title={COPY_LOGIN_TITLE}
      subtitle="Please confirm your email address used when signing up to view your Dashboard"
      signup
    >
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="px-2 sm:mx-auto sm:w-full sm:max-w-md sm:px-0">
          <form
            className="space-y-6"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <FormInputEmail
              id="email"
              label="Email"
              register={register}
              error={errors?.email?.message}
            />
            <CTAButton
              type="submit"
              label="Submit"
              enabled={Boolean(input_email)}
              full
            />
          </form>
        </div>
      </div>
    </AuthScreenBase>
  );
}
