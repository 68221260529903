import { useMutation } from "@apollo/client";
import { ChartPieIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  Bars3BottomRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { resetTrackingUser, trackPage } from "analytics";
import { MUTATION_LOGOUT } from "api/mutations/account";
import BookACall from "components/BookACall";
import IconTimeline from "components/Image/Icons/Timeline";
import Loading from "components/Loading";
import { NavigationOptionsType } from "components/Navigation/types";
import { APP_URLS } from "settings";
import { useUserStore } from "store/User";
import { classNames } from "utils/helpers";

export default function NavigationMobile({
  navOpen,
  toggleNav,
}: NavigationOptionsType) {
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const user = useUserStore((state) => state.user);
  const eligibleForCall = useUserStore((state) => state.eligibleForCall);
  const eligibleForLead = useUserStore((state) => state.eligibleForLead);
  const equityCheckIsSuccess = useUserStore(
    (state) => state.equityCheckIsSuccess
  );

  const navigate = useNavigate();

  const [logout, { error: mutationError, data: mutationData }] =
    useMutation(MUTATION_LOGOUT);

  /**
   * Handle logout response, redirecting with hard refresh that will handle
   * authentication check and onward redirect
   */
  useEffect(() => {
    if (mutationData || mutationError) {
      setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 1000);
    }
  }, [mutationData, mutationError]);

  /**
   * Trigger logout
   */
  const handleLogout = () => {
    setLoggingOut(true);
    trackPage("Logout");
    resetTrackingUser();
    logout();
  };

  const handleDashboardNav = () => {
    toggleNav();
    navigate(APP_URLS.DASHBOARD);
  };

  const renderBookACall = () =>
    eligibleForCall &&
    eligibleForLead && (
      <BookACall customAction={toggleNav}>
        <span className="flex items-center hover:text-brand-green-bright">
          <ChatBubbleOvalLeftEllipsisIcon className="mr-3 h-9 w-9" />
          <span>Book a call</span>
        </span>
      </BookACall>
    );

  return (
    <div
      className={classNames(
        navOpen ? "block" : "hidden",
        "brand-hero fixed z-50 min-h-screen w-full overflow-hidden"
      )}
    >
      <div className="mx-auto 2xl:container sm:px-6 lg:px-8">
        <div className="flex h-20 items-center justify-end px-4 sm:px-0">
          <button
            onClick={() => toggleNav()}
            className="inline-flex items-center justify-center text-white hover:text-brand-green-bright"
          >
            <span className="sr-only">Open main menu</span>
            {navOpen ? (
              <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
            ) : (
              <Bars3BottomRightIcon className="block h-8 w-8" />
            )}
          </button>
        </div>
      </div>
      <div className="mt-[30%] flex h-full items-center justify-center md:mt-[15%]">
        {loggingOut ? (
          <Loading large />
        ) : (
          <div className="space-y-10 text-2xl text-white md:text-3xl">
            {user ? (
              <>
                <button
                  className="flex items-center hover:text-brand-green-bright"
                  onClick={handleDashboardNav}
                >
                  <ChartPieIcon className="mr-3 h-9 w-9" />
                  <span>Dashboard</span>
                </button>
                {equityCheckIsSuccess && (
                  <button
                    type="button"
                    className="flex items-center"
                    onClick={() => navigate(APP_URLS.EQUITY_TIMELINE)}
                  >
                    <IconTimeline />
                    <span className="ml-4">Timeline</span>
                  </button>
                )}
                {renderBookACall()}
                {user.isVerified && (
                  <button
                    type="button"
                    className="flex items-center hover:text-brand-green-bright"
                    onClick={() => navigate(APP_URLS.ACCOUNT.ACCOUNT_PROFILE)}
                  >
                    <UserCircleIcon className="mr-3 h-9 w-9" />
                    <span>Account details</span>
                  </button>
                )}
                <button
                  className="flex items-center hover:text-brand-green-bright"
                  onClick={handleLogout}
                >
                  <ArrowLeftOnRectangleIcon className="mr-3 h-9 w-9" />
                  <span>Logout</span>
                </button>
              </>
            ) : (
              <>
                {renderBookACall()}
                <a
                  className="flex items-center text-white hover:text-brand-green-bright"
                  href={APP_URLS.LOGIN}
                >
                  <ArrowRightOnRectangleIcon className="mr-3 h-9 w-9" />
                  <span>Login</span>
                </a>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
