import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  ADDRESS_DETAIL_QUERY,
  ADDRESS_LOOKUP_QUERY,
} from "api/queries/address";

import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { debounce } from "utils/helpers";

type FormInputAddressProps = FormInputProps & {
  initialAddress: any;
  setValue: Function;
  triggerManualInput: any;
};

export default function FormInputAddress({
  id,
  label,
  register,
  initialAddress,
  error,
  setValue,
  triggerManualInput,
}: FormInputAddressProps) {
  const [addresses, setAddresses] = useState([]);
  const [hadInitialSearch, setHadInitialSearch] = useState(false);
  const [getAddresses] = useLazyQuery(ADDRESS_LOOKUP_QUERY);
  const [getAddressDetail] = useLazyQuery(ADDRESS_DETAIL_QUERY);

  const handleAddressSelection = async (address: string, id: string) => {
    setValue({ address });
    setAddresses([]);
    const { data } = await getAddressDetail({ variables: { id: id } });
    if (data.addressDetail) {
      setValue(data.addressDetail);
    }
    return;
  };

  const refetchAddress = async (newVal: string) => {
    const { data } = await getAddresses({ variables: { search: newVal } });
    setAddresses(data?.addressLookup || []);
    return;
  };

  const handleOnChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = event.target.value;

      if (newVal) {
        refetchAddress(newVal);
      } else {
        setAddresses([]);
      }
    },
    50
  );

  const inputProps =
    id && register
      ? {
          ...register(id, {
            required: true,
            onChange: handleOnChange,
          }),
        }
      : {};

  useEffect(() => {
    // If there is some prefilled value which is passed to
    // initialAddress props then refetch address
    if (initialAddress && !hadInitialSearch) {
      refetchAddress(initialAddress);
      setHadInitialSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAddress]);

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          name={id}
          type="text"
          required
          className={STYLE_INPUT}
          autoComplete="off"
          {...inputProps}
        />
        {addresses.length ? (
          <div className="mt-1 max-h-52 w-full flex-col overflow-scroll rounded border border-gray-200 bg-white shadow">
            {addresses.map(({ address, id }, key) => {
              return (
                <div
                  className="group cursor-pointer border-t"
                  key={key}
                  onClick={() => handleAddressSelection(address, id)}
                >
                  <span className="block border-l-4 border-transparent p-2 text-base group-hover:bg-gray-50">
                    {address}
                  </span>
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="flex items-start justify-between">
          {error ? (
            <FormInputError error={error} />
          ) : (
            <p className="ml-1 mt-2 max-w-sm text-sm text-brand-copy-light">
              Start typing your address address here, or continue to manually
              input the address if we can't find it.
            </p>
          )}
          <button
            onClick={triggerManualInput}
            className="link ml-2 mr-1 mt-2 text-right text-sm underline"
            type="button"
          >
            Enter manually
          </button>
        </div>
      </div>
    </div>
  );
}
