import { useEffect, useState } from "react";

import EquityStat from "components/Dashboard/Stats/Stat";
import PropertyValueStat from "components/Dashboard/Stats/PropertyValueStat";
import STATS, {
  getSecondMortgageStatType,
} from "components/Dashboard/Stats/config";
import { useUserStore } from "store/User";
import { CHECK_STATUS } from "pages/Dashboard/components/EquityCheck/consts";

type ECStatType = {
  eqId?: string | undefined;
  propertyValuation?: number | undefined;
  internalPropertyValuation?: number | undefined;
  equity?: number | undefined;
  creditCheckMortgageBalance?: number | undefined;
  secondMortgageBalance?: number | undefined;
  secondMortgageIsAhauzLoan?: boolean | undefined;
};

export default function StatsBar() {
  const [statData, setStatData] = useState<ECStatType>({});
  const [renderStat, setRenderStat] = useState<boolean>(false);

  const equityCheck = useUserStore((state) => state.equityCheck);

  useEffect(() => {
    if (equityCheck) {
      setStatData({
        eqId: equityCheck?.id,
        propertyValuation: equityCheck?.live?.propertyValuation,
        internalPropertyValuation: equityCheck?.live?.internalPropertyValuation,
        equity: equityCheck?.live?.equity,
        creditCheckMortgageBalance:
          equityCheck?.live?.creditCheckMortgageBalance,
        secondMortgageBalance: equityCheck?.live?.secondMortgageBalance,
        secondMortgageIsAhauzLoan: equityCheck?.live?.secondMortgageIsAhauzLoan,
      });
      setRenderStat(equityCheck?.checkStatus === CHECK_STATUS.SUCCESS);
    } else {
      setStatData({});
      setRenderStat(false);
    }
  }, [equityCheck]);

  return (
    <div className="no-scroll-bar flex gap-4 overflow-y-scroll px-4 pb-3 lg:grid lg:grid-cols-4 lg:gap-3 lg:gap-6 lg:px-0 lg:pb-0 lg:pb-0">
      <PropertyValueStat
        eqId={statData.eqId}
        stat={STATS.propertyValue}
        value={statData.propertyValuation}
        internalValuation={statData.internalPropertyValuation}
        renderStat={renderStat}
      />
      <EquityStat
        stat={STATS.equity}
        value={statData.equity}
        renderStat={renderStat}
      />
      <EquityStat
        stat={STATS.mortgage}
        value={statData.creditCheckMortgageBalance}
        renderStat={renderStat}
      />
      {statData.secondMortgageBalance && (
        <EquityStat
          stat={getSecondMortgageStatType(
            equityCheck?.live?.secondMortgageIsAhauzLoan
          )}
          value={equityCheck?.live?.secondMortgageBalance}
          renderStat={renderStat}
        />
      )}
    </div>
  );
}
