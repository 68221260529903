import { useMutation, useQuery } from "@apollo/client";
import { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";

import { trackPage } from "analytics";
import { MUTATION_SET_USER_PASSWORD } from "api/mutations/account";
import { QUERY_VALIDATE_ACCOUNT_RESET_PASSWORD_TOKEN } from "api/queries/account";
import AlertSuccess from "components/Alert/Success";
import AuthScreenBase from "components/Auth/AuthScreenBase";
import * as consts from "pages/Account/ResetPassword/consts";
import AccountFormSetPassword from "pages/Account/components/Form/SetPassword";
import { APP_URLS } from "settings";

export default function ResetPassword() {
  let params = useParams();
  let { uidb64, token } = params;

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(QUERY_VALIDATE_ACCOUNT_RESET_PASSWORD_TOKEN, {
    variables: {
      uidb64: uidb64,
      token: token,
    },
  });

  const [resetPassword, mutationState] = useMutation(
    MUTATION_SET_USER_PASSWORD
  );

  useEffect(() => {
    trackPage("Reset password");
  }, []);

  const handleFormSubmit = (formData: any) => {
    resetPassword({
      variables: {
        userPassword: {
          uidb64: uidb64,
          newPassword1: formData.newPassword1,
          newPassword2: formData.newPassword2,
        },
      },
    });
  };

  /**
   * Render success message when account has been created
   * @returns ReactElement
   */
  const renderCreateAccountSuccess = (): ReactElement => (
    <AlertSuccess title={consts.COPY_RESET_PASSWORD_SUCCESS_TITLE} shadow>
      <p>
        Your password has been reset and we'll now redirect you to your{" "}
        <a
          href={APP_URLS.DASHBOARD}
          className="text-green-800 underline hover:text-green-800"
        >
          Dashboard
        </a>
        .
      </p>
    </AlertSuccess>
  );

  return (
    <AuthScreenBase
      title={consts.COPY_RESET_PASSWORD_TITLE}
      subtitle="Set a new account password to login to your Dashboard"
      signup
    >
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="px-2 sm:mx-auto sm:w-full sm:max-w-md sm:px-0">
          <AccountFormSetPassword
            loading={queryLoading}
            error={queryError}
            errorMessage={consts.COPY_INVALID_RESET_URL}
            ok={queryData && queryData?.accountValidateResetPasswordToken?.ok}
            onSubmit={handleFormSubmit}
            onSuccess={renderCreateAccountSuccess}
            mutationState={mutationState}
          />
        </div>
      </div>
    </AuthScreenBase>
  );
}
