import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { trackPage } from "analytics";
import Dashboard from "components/Dashboard";
import EquityCheck from "pages/Dashboard/components/EquityCheck";
import { APP_URLS } from "settings";
import { useUserStore } from "store/User";

export default function PageDashboard() {
  const [searchParams] = useSearchParams();
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    trackPage("Dashboard", {
      medium: searchParams.get("medium"),
      source: searchParams.get("source"),
    });
  }, [searchParams]);

  if (!user) {
    return <></>;
  }

  if (user.verificationCheck || user.verificationFail)
    return <Navigate to={APP_URLS.ACCOUNT.ACCOUNT_VERIFICATION} />;

  // Todo: Child routes different dashboard types

  return (
    <Dashboard
      title={`${user.firstName}'s <br class="block md:hidden">Dashboard`}
      showStats={true}
    >
      <EquityCheck />
    </Dashboard>
  );
}
