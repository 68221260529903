import * as yup from "yup";

import { validateConfirmPassword, VALIDATION_REQUIRED } from "utils/validation";

export const schema = yup.object({
  oldPassword: yup.string().required(VALIDATION_REQUIRED),
  newPassword1: yup.string().required(VALIDATION_REQUIRED),
  newPassword2: validateConfirmPassword("newPassword1"),
});

export default schema;
