import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { QUERY_APPOINTMENTS } from "api/queries/appointments";
import Card from "components/Card";
import CRMRescheduleAppointment from "components/CRM/RescheduleAppointment";
import Container from "components/Dashboard/Container";
import Wrapper from "components/Dashboard/Wrapper";
import Loading from "components/Loading";
import Appointment from "pages/Dashboard/components/Appointments/Appointment";
import { useUserStore } from "store/User";
import { AppointmentType } from "ts/types";
import { classNames, wait } from "utils/helpers";
import IconConvo from "components/Image/Icons/Convo";
import IconArrowLeft from "components/Image/Icons/ArrowLeft";
import IconArrowRight from "components/Image/Icons/ArrowRight";
import BookACall from "components/BookACall";

const genDate = (date: moment.Moment) => ({
  day: date.format("dddd"),
  date: date.format("D"),
  month: date.format("MMMM"),
});

export default function Appointments() {
  const [rescheduling, setRescheduling] = useState<string | null>(null);
  const [stateLoading, setStateLoading] = useState<boolean>(false);

  const appointments = useUserStore((state) => state.appointments);
  const appointmentTrigger = useUserStore((state) => state.appointmentTrigger);

  const setAppointments = useUserStore((state) => state.setAppointments);
  const setAppointmentTrigger = useUserStore(
    (state) => state.setAppointmentTrigger
  );

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
    refetch,
    client,
  } = useQuery(QUERY_APPOINTMENTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    setAppointments(queryData?.customerAppointments);
  }, [queryData, setAppointments]);

  /**
   * Trigger query refetch, force timeout due to HubSpot timing dependency on data change
   */
  const doRefetch = useCallback(() => {
    (async () => {
      await wait(4000);
      await refetch();
      setStateLoading(false);
    })();
  }, [refetch]);

  /**
   * When we get an appointment activity trigger, clear state and refetch appointments
   */
  useEffect(() => {
    if (appointmentTrigger === true) {
      setStateLoading(true);
      setAppointmentTrigger(false);
      setRescheduling(null);
      doRefetch();
    }
  }, [appointmentTrigger, doRefetch, setAppointmentTrigger]);

  const bookingDays = [
    genDate(moment()),
    genDate(moment().add("1", "days")),
    genDate(moment().add("2", "days")),
    genDate(moment().add("3", "days")),
  ];

  const handleReschedule = (rescheduleUrl: string) =>
    setRescheduling(rescheduleUrl);

  const isLoading = queryLoading || stateLoading;
  const hasAppointments =
    !isLoading && !queryError && appointments && appointments.length > 0;

  /**
   * Remove an appointment from local state after cancelling to avoid refetch
   * @param appointment AppointmentType
   */
  const popLocalStateAppointment = (appointment: AppointmentType) => {
    if (appointments) {
      // Update Apollo cache for next fetch
      client.cache.evict({ id: client.cache.identify(appointment) });
      client.cache.gc();

      // New array of appointments without cancelled appointment
      const localStateAppointments = appointments.filter(
        (app) => app.id !== appointment.id
      );

      // Update store
      setAppointments(localStateAppointments);
    }
  };

  const renderAppointments = () => {
    return (
      <>
        <p className="text-center text-base font-semibold">Scheduled calls</p>
        <div className="flex flex h-full items-center justify-center pb-4 pt-2">
          <ul className="space-y-4">
            {appointments &&
              appointments.map((appointment: AppointmentType, i: number) => (
                <Appointment
                  key={i}
                  appointment={appointment}
                  onReschedule={handleReschedule}
                  onCancel={() => popLocalStateAppointment(appointment)}
                />
              ))}
          </ul>
        </div>
      </>
    );
  };

  const renderNoAppsFlag = () => {
    if (isLoading) {
      return (
        <div className="mt:0 mx-auto">
          <Loading />
        </div>
      );
    }

    if (queryError) {
      return (
        <div className="flex h-full items-center justify-center">
          <p className="text-center text-base leading-10">
            Sorry, we can not load your appointments at this time.
          </p>
        </div>
      );
    }

    return (
      <>
        <p className="mb-2 text-center text-base font-semibold md:mb-0">
          Select a date to view availability
        </p>
        <div className="flex flex h-full items-center justify-center pb-4 pt-2">
          <BookACall title="Book a call">
            <div className="relative flex cursor-pointer flex-nowrap justify-center space-x-4">
              <div className="flex items-center justify-center">
                <IconArrowLeft />
              </div>
              <div className="flex space-x-4 pl-4 pr-4">
                {bookingDays.map((date, index) => (
                  <div
                    key={index}
                    className={classNames(
                      index > 2
                        ? "hidden xl:flex"
                        : index > 0
                        ? "hidden md:flex"
                        : "flex",
                      "effect-cta-gradient-hover w-40 flex-none flex-col space-y-3 rounded-2xl py-6 text-center text-lg font-semibold uppercase shadow-2xl"
                    )}
                  >
                    <span>{date.day}</span>
                    <span className="text-7xl font-normal">{date.date}</span>
                    <span>{date.month}</span>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-center">
                <IconArrowRight />
              </div>
            </div>
          </BookACall>
        </div>
      </>
    );
  };

  return (
    <Wrapper>
      <>
        <Container>
          <>
            <div className="gap-0 px-4 md:px-8 lg:grid lg:grid-cols-5">
              <div className="col-span-2">
                <div className="flex h-full flex-col items-center px-2 pt-7 text-center md:flex-row md:pt-3 md:text-left lg:pt-0">
                  <div className="pr-2">
                    <IconConvo />
                  </div>
                  <Card title="Book a call" />
                </div>
              </div>
              <div className="col-span-3 pb-3 pt-3 lg:px-4 lg:pb-10 lg:pt-10">
                {hasAppointments ? renderAppointments() : renderNoAppsFlag()}
              </div>
            </div>
          </>
        </Container>
        {rescheduling && (
          <CRMRescheduleAppointment rescheduleURL={rescheduling} />
        )}
      </>
    </Wrapper>
  );
}
