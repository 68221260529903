import React from "react";
import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "css/App.css";
import App from "App";

import { getCookie } from "utils/helpers";
import reportWebVitals from "reportWebVitals";
import { initAnalytics } from "analytics";
import { COOKIES } from "settings";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.1,
  });
}

initAnalytics();

const apolloLink = createHttpLink({
  uri: process.env.REACT_APP_API,
  credentials: "include",
  headers: {
    "X-CSRFToken": getCookie(COOKIES.CSRF),
  },
});

const client = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
