import {
  useLocation,
  useSearchParams,
  Navigate,
  Outlet,
} from "react-router-dom";
import { passOnSearchParams } from "routes/utils";

import { APP_URLS } from "settings";

/**
 * Route authenticated users to requested page, else to Login page
 */
export default function RouteRequireAuth({
  authenticated,
}: {
  authenticated: boolean;
}) {
  const [searchParams] = useSearchParams();
  let location = useLocation();

  if (!authenticated) {
    const navigateTo = `${APP_URLS.LOGIN}${passOnSearchParams(searchParams)}`;
    return <Navigate to={navigateTo} state={{ from: location }} />;
  }

  return <Outlet />;
}
