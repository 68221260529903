import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import AuthScreenBase from "components/Auth/AuthScreenBase";
import Loading from "components/Loading";
import PageDialogScreen from "components/Page/DialogScreen";
import MetaInfo from "pages/GetStarted/components/MetaInfo";
import StepComplete from "pages/GetStarted/components/StepComplete";
import StepOne from "pages/GetStarted/components/StepOne";
import StepTwo from "pages/GetStarted/components/StepTwo";
import { APP_URLS, EXTERNAL_API } from "settings";
import { useUserStore } from "store/User";
import { GET_STARTED_COPY } from "./consts";

export default function GetStarted() {
  const [countryDetected, setCountryDected] = useState(false);

  const { REQUIRE_PROPERTY_DETAILS } = useUserStore(
    (state) => state.userCountry
  );
  const setUserCountry = useUserStore((state) => state.setUserCountry);

  useEffect(() => {
    /**
     * Look up user country and set country code to state
     */
    const getUserCountry = async () => {
      try {
        const response = await fetch(EXTERNAL_API.IP_LOOKUP);
        const json = await response.json();
        const country = json?.location?.country?.code || "";

        // set state with the result
        setUserCountry(country);
      } catch (error) {
        Sentry.captureException(error);
      }

      setTimeout(() => setCountryDected(true), 300);
    };

    // TODO: IF INCOMING COUNTRY PARAM / COMING IN FROM INDIA LANDING PAGE? DO WE
    // CARE IF THE USER IS ACTUALLY IN INDIA OR JUST SEES A DIFFERENT VERSION?
    getUserCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderGetStartedRoutes = () => (
    <AuthScreenBase
      title={GET_STARTED_COPY.title}
      subtitle={GET_STARTED_COPY.subtitle}
    >
      <>
        <Routes>
          {REQUIRE_PROPERTY_DETAILS ? (
            <>
              <Route
                path={APP_URLS.GET_STARTED.PROPERTY}
                element={<StepOne />}
              />
              <Route
                path={APP_URLS.GET_STARTED.ACCOUNT}
                element={<StepTwo />}
              />
            </>
          ) : (
            <Route path={APP_URLS.GET_STARTED.PROPERTY} element={<StepTwo />} />
          )}
          <Route
            path={APP_URLS.GET_STARTED.COMPLETE}
            element={<StepComplete />}
          />
        </Routes>
        <MetaInfo />
      </>
    </AuthScreenBase>
  );

  if (countryDetected) {
    return renderGetStartedRoutes();
  }

  return (
    <PageDialogScreen logo={false}>
      <Loading large />
    </PageDialogScreen>
  );
}
