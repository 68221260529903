import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

type Props = {
  title?: string;
  children: JSX.Element;
};

export default function AlertWarning({ title, children }: Props) {
  return (
    <div className="rounded-2.5xl border border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 text-base text-yellow-700">
          {title && <p className="text-base font-medium">{title}</p>}
          <div className={title ? "mt-2" : ""}>{children}</div>
        </div>
      </div>
    </div>
  );
}
