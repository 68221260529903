import { useMutation } from "@apollo/client";
import React, { ReactElement, useEffect, useState } from "react";

import { trackPage } from "analytics";
import { MUTATION_FORGOT_PASSWORD } from "api/mutations/account";
import AlertSuccess from "components/Alert/Success";
import AuthScreenBase from "components/Auth/AuthScreenBase";
import Form from "components/Form";
import FormInputEmail, { isValidEmail } from "components/Form/Input/Email";
import PageContainer from "components/Page/Container";
import * as consts from "pages/Account/ForgotPassword/consts";
import * as types from "pages/Account/ForgotPassword/index.types";
import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";

export default function AccountForgotPassword() {
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [formData, setFormData] = useState<types.FormDataType>({
    email: null,
  });
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [forgotPassword, { loading: mutationLoading }] = useMutation(
    MUTATION_FORGOT_PASSWORD
  );

  useEffect(() => {
    trackPage("Forgot password");
  }, []);

  /**
   * On input change, reset any form data and reset form data
   * @param event
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setCanSubmit(isValidEmail(email));
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  /**
   * Trigger reset password email send
   */
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    forgotPassword({
      variables: {
        email: formData.email,
        userType: "CUSTOMER",
      },
    });

    setSubmitted(true);
  };

  const resetComplete = (): ReactElement => (
    <AlertSuccess title={consts.COPY_FORGOT_PASSWORD_SUCCESS_TITLE} shadow>
      <p>
        We'll now send you an email to reset your password. If you're still
        having issues, please contact our Customer Support team on{" "}
        {AHAUZ_PHONE_NUMBER}.
      </p>
    </AlertSuccess>
  );

  const resetForm = (): ReactElement => (
    <PageContainer>
      <Form
        onSubmit={handleFormSubmit}
        canSubmit={canSubmit}
        submitting={mutationLoading}
      >
        <FormInputEmail onChange={handleInputChange} />
      </Form>
    </PageContainer>
  );

  const renderResetPassword = (): ReactElement => {
    if (submitted) {
      return resetComplete();
    }
    return resetForm();
  };

  return (
    <AuthScreenBase
      title={consts.COPY_FORGOT_PASSWORD_TITLE}
      subtitle="Please submit your registered email address, we'll send you an email to reset your password."
      signup
    >
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="px-2 sm:mx-auto sm:w-full sm:max-w-md sm:px-0">
          {renderResetPassword()}
          <div className="mt-8 text-center text-base text-brand-copy-light">
            Remembered your password?{" "}
            <a href={APP_URLS.LOGIN}>Click here to log in</a>
          </div>
        </div>
      </div>
    </AuthScreenBase>
  );
}
