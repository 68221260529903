import { useEffect } from "react";

import RegulatedFlag from "components/Image/RegulatedFlag";
import { useUserStore } from "store/User";

export default function MetaInfo() {
  const { SHOW_META_INFO } = useUserStore((state) => state.userCountry);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (SHOW_META_INFO) {
    return (
      <>
        <div className="flex flex-col items-center border-t border-gray-300 pt-8 sm:flex-row sm:items-start sm:justify-between">
          <RegulatedFlag />
          <div
            className="trustpilot-widget mt-4 sm:mt-0"
            data-locale="en-GB"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="600332ca10c3780001007141"
            data-style-height="24px"
            data-style-width="220px"
            data-theme="light"
            data-min-review-count="100"
            data-style-alignment="center"
          >
            <a
              href="https://uk.trustpilot.com/review/ahauz.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </div>
      </>
    );
  }

  return <></>;
}
