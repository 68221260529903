import moment from "moment";
import * as yup from "yup";

import { SCHEMA_USER, VALIDATION_REQUIRED } from "utils/validation";

export const DOB_INVALID =
  "Please enter a valid date of birth in the format DD MM YYYY";

export const getPropertySchema = (requirePropertyDetails: boolean) => {
  if (requirePropertyDetails) {
    return yup.object({
      addressLine1: yup.string().required(VALIDATION_REQUIRED),
      addressTownOrCity: yup.string().required(VALIDATION_REQUIRED),
      addressPostcode: yup
        .string()
        .trim()
        .required(VALIDATION_REQUIRED)
        .matches(
          /^[A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}$/,
          "Please enter a valid postcode"
        ),
      propertyType: yup
        .string()
        .required(VALIDATION_REQUIRED)
        .typeError(VALIDATION_REQUIRED),
      buildingType: yup
        .string()
        .required(VALIDATION_REQUIRED)
        .typeError(VALIDATION_REQUIRED),
      bedroomCount: yup
        .string()
        .required(VALIDATION_REQUIRED)
        .typeError(VALIDATION_REQUIRED),
    });
  }
  return yup.object({});
};

export const getAccountSchema = (
  requirePhoneNumber: boolean,
  requireTitle: boolean,
  requireSignupDataset: boolean
) =>
  yup.object({
    ...SCHEMA_USER,
    ...{
      title: requireTitle
        ? yup.string().required(VALIDATION_REQUIRED)
        : yup.string().optional().nullable(),
      phoneNumber: requirePhoneNumber
        ? yup.string().required(VALIDATION_REQUIRED)
        : yup.string().optional().nullable(),
      dateOfBirth: yup
        .string()
        .required(VALIDATION_REQUIRED)
        .test(
          "dateOfBirth",
          "Should be greater than 18",
          function (value, ctx) {
            if (value) {
              const isAge18Over = moment().diff(moment(value), "years") >= 18;
              if (!isAge18Over) {
                return ctx.createError({
                  path: "dateOfBirth",
                  message: "You must be aged 18 or over to use this service",
                });
              }
            }
            return true;
          }
        ),
      password1: yup.string().required(VALIDATION_REQUIRED),
      dateOfBirthPartDay: yup
        .number()
        .required()
        .positive()
        .integer()
        .min(1, DOB_INVALID)
        .max(31, DOB_INVALID),
      dateOfBirthPartMonth: yup
        .number()
        .required(DOB_INVALID)
        .positive()
        .integer()
        .min(1, DOB_INVALID)
        .max(12, DOB_INVALID),
      dateOfBirthPartYear: yup
        .number()
        .required(DOB_INVALID)
        .positive()
        .integer(),
      propertyValue: requireSignupDataset
        ? yup.string().required(VALIDATION_REQUIRED)
        : yup.string().optional().nullable(),
      mortgageBalance: requireSignupDataset
        ? yup.string().required(VALIDATION_REQUIRED)
        : yup.string().optional().nullable(),
    },
  });
