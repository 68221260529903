import AuthScreenBase from "components/Auth/AuthScreenBase";
import PageContainer from "components/Page/Container";
import { APP_URLS } from "settings";

export default function Page404() {
  return (
    <AuthScreenBase signup title="404 - Page not found">
      <PageContainer>
        <div className="text-center">
          <p className="mb-2 text-lg font-medium">
            Sorry, but it looks like the page you are looking does not exist.
          </p>
          <p>
            If you're trying to Log in or access your Dashboard, please{" "}
            <a href={APP_URLS.DASHBOARD}>click here</a>.
          </p>
        </div>
      </PageContainer>
    </AuthScreenBase>
  );
}
