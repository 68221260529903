import { useState } from "react";

import Button from "components/CTA/Button";
import FormInputCheckbox from "components/Form/Input/CheckBox";
import FormInputError from "components/Form/Input/Error";
import {
  LastMortgageYearQaType,
  MortgageQaType,
} from "pages/Account/AccountVerification/types";

interface PropertiesType {
  lastMortgageYearQa: LastMortgageYearQaType;
  mortgageQa: MortgageQaType;
  loading: boolean;
  error: Function;
  handleMortgageYearSubmit: Function;
}

export default function LastMortgageYear({
  lastMortgageYearQa,
  mortgageQa,
  loading,
  error,
  handleMortgageYearSubmit,
}: PropertiesType) {
  const [lastMortgageYearAnswer, setLastMortgageYear] = useState<string | null>(
    null
  );
  const [validationError, setValidationError] = useState<boolean>(false);

  if (!lastMortgageYearQa || mortgageQa) {
    return null;
  }

  const handleAnswerQuestion = () => {
    setValidationError(false);

    if (!lastMortgageYearAnswer) {
      setValidationError(true);
      return;
    }

    handleMortgageYearSubmit(lastMortgageYearAnswer);
  };

  return (
    <div className="mt-4">
      <div className="mb-4 text-center text-lg">
        To ensure your security and verify your identity, we need to ask you 1
        or 2 questions
      </div>

      <div className="mb-5">
        <div>{lastMortgageYearQa.question}</div>

        {lastMortgageYearQa.options.map((option: string, index: number) => {
          return (
            <div className="mt-4 flex items-center" key={index}>
              <FormInputCheckbox
                id={`mortgage-year-q-${index}`}
                label={
                  option === "EMPTY"
                    ? "None of these / I do not have a mortgage on this property"
                    : option
                }
                checked={lastMortgageYearAnswer === option}
                onChange={() => setLastMortgageYear(option)}
              />
            </div>
          );
        })}

        {validationError && (
          <FormInputError error="Please provide an answer to proceed" />
        )}
      </div>

      {error()}

      <div className="mt-8 flex justify-end">
        <Button
          label="Submit Verification"
          loading={loading}
          onClick={handleAnswerQuestion}
        />
      </div>
    </div>
  );
}
