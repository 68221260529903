export const TIMELINE_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DEFAULT_DATASET_CONFIG = {
  spanGaps: true,
  pointRadius: 5,
  pointHoverRadius: 10,
  pointHoverBorderWidth: 10,
};

export const DATASET_INDEX_PROPERTY_VALUE = 0;
export const DATASET_INDEX_MORTGAGE_BALANCE = 1;
export const DATASET_INDEX_EQUITY = 2;

export const PROPERTY_VALUE_CONFIG = {
  ...DEFAULT_DATASET_CONFIG,
  label: "Property value",
  data: [],
  borderColor: "#00DEA3",
  pointBackgroundColor: "#00DEA3",
  pointBorderColor: "rgba(0, 222, 163, 0.7)",
};

export const MORTGAGE_BALANCE_CONFIG = {
  ...DEFAULT_DATASET_CONFIG,
  label: "Mortgage",
  data: [],
  borderColor: "#0D493B",
  pointBackgroundColor: "#0D493B",
  pointBorderColor: "rgba(13, 73, 59, 0.7)",
};

export const EQUITY_CONFIG = {
  ...DEFAULT_DATASET_CONFIG,
  label: "Equity",
  data: [],
  borderColor: "#FF8D3B",
  pointBackgroundColor: "#FF8D3B",
  pointBorderColor: "rgba(255, 141, 59, 0.7)",
};

export const CHART_DATASET_TYPES = [
  {
    label: PROPERTY_VALUE_CONFIG.label,
    color: "brand-green-medium",
  },
  {
    label: MORTGAGE_BALANCE_CONFIG.label,
    color: "brand-green-dark",
  },
  {
    label: EQUITY_CONFIG.label,
    color: "brand-orange-medium",
  },
];
