const DEFAULT_FILL_COLOUR = "#00DEA3";

export default function IconBank({ fill }: { fill?: string }) {
  const iconFillColour = fill ? fill : DEFAULT_FILL_COLOUR;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 7.97214V13.242H2.8137V26.3726H0V32H31.8886V26.3726H29.0749V13.242H31.8886V7.97214L15.9443 0L0 7.97214ZM13.1306 26.3726H8.44109V13.242H13.1306V26.3726ZM18.758 13.242H23.4475V26.3726H18.758V13.242Z"
        fill={iconFillColour}
      />
    </svg>
  );
}
