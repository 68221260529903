import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { MUTATION_CHANGE_PASSWORD } from "api/mutations/account";

import AlertError from "components/Alert/Error";
import Card from "components/Card";
import CardBody from "components/Card/Body";
import Container from "components/Dashboard/Container";
import CTAButton from "components/CTA/Button";
import FormInputPassword from "components/Form/Input/Password";
import Loading from "components/Loading";
import * as consts from "pages/Account/AccountProfile/components/Password/consts";
import schema from "pages/Account/AccountProfile/components/Password/schema";
import { FormInputs } from "pages/Account/AccountProfile/components/Password/types";
import {
  COPY_FORM_ERROR_TRY_AGAIN,
  COPY_FORM_SUCCESS_DETAILS_SAVED,
} from "pages/Account/AccountProfile/consts";
import { useNotificationStore } from "store/Notifications";
import { COPY_FORM_ERROR_CONTACT_SUPPORT } from "utils/copy";

export default function Password() {
  const createNotification = useNotificationStore(
    (state) => state.createNotification
  );

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
    },
  });

  const [
    changePassword,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_CHANGE_PASSWORD);

  const formErrors = mutationData?.response?.errors;
  const hasErrors =
    formErrors ||
    mutationError ||
    (mutationData?.response?.ok === false && !formErrors);

  const success = mutationData?.response?.ok && !hasErrors;

  /**
   * Handle submission errors
   */
  useEffect(() => {
    if (mutationData?.response?.ok) {
      reset();
    }

    if (!mutationData?.response?.ok && mutationData?.response?.errors) {
      const serverErrors: FormInputs = mutationData.response.errors;

      for (let [key, errors] of Object.entries(serverErrors)) {
        setError(key as keyof FormInputs, {
          type: "server",
          message: errors[0],
        });
      }
    }

    if (success) {
      createNotification({
        title: consts.COPY_UPDATE_PASSWORD_SUCCESS_TITLE,
        description: COPY_FORM_SUCCESS_DETAILS_SAVED,
      });
    }
  }, [mutationData, setError, reset, createNotification, success]);

  useEffect(() => {
    if (mutationError) {
      createNotification({
        type: "error",
        title: "Error updating your password",
        description: COPY_FORM_ERROR_CONTACT_SUPPORT,
      });
    }
  }, [mutationError, createNotification]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    changePassword({
      variables: {
        input: {
          oldPassword: data.oldPassword,
          newPassword1: data.newPassword1,
          newPassword2: data.newPassword2,
        },
      },
    });
  };

  return (
    <Container>
      <Card
        title={consts.COPY_UPDATE_PASSWORD_TITLE}
        subtitle="Change your account password"
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardBody>
            <>
              <div className="mb-6 grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <FormInputPassword
                    id="oldPassword"
                    label="Current password"
                    register={register}
                    error={errors?.oldPassword?.message}
                    validate={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <FormInputPassword
                    id="newPassword1"
                    label="New password"
                    register={register}
                    error={errors?.newPassword1?.message}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <FormInputPassword
                    id="newPassword2"
                    label="Confirm password"
                    register={register}
                    validate={false}
                    error={errors?.newPassword2?.message}
                  />
                </div>
              </div>
              {hasErrors && (
                <div className="mt-5">
                  <AlertError title="Error updating your password">
                    <p>
                      {mutationError
                        ? COPY_FORM_ERROR_CONTACT_SUPPORT
                        : COPY_FORM_ERROR_TRY_AGAIN}
                    </p>
                  </AlertError>
                </div>
              )}
            </>
          </CardBody>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            {mutationLoading ? (
              <div className="mr-3 inline-block">
                <Loading />
              </div>
            ) : (
              <CTAButton type="submit" label="Save" />
            )}
          </div>
        </form>
      </Card>
    </Container>
  );
}
