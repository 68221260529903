import {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";

import Form from "components/Form";
import FormInputPassword, {
  isPasswordMatch,
  isValidPassord,
} from "components/Form/Input/Password";
import Loading from "components/Loading";
import PageContainer from "components/Page/Container";

import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";

import * as types from "pages/Account/components/Form/SetPassword.types";

export const INVALID_PASSWORD_CONFIRMATION =
  "Passwords do not match, please try again.";

export default function AccountFormSetPassword({
  ok,
  loading,
  error,
  onSubmit,
  onSuccess,
  mutationState,
  submitCTA,
  errorMessage = "Sorry, this has been an error.",
}: types.Props) {
  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<Array<string> | null>(null);
  const [formData, setFormData] = useState<types.FormDataType>({
    newPassword1: null,
    newPassword2: null,
  });

  /**
   * On successfull submit, redirect to dashboard
   */
  useEffect(() => {
    if (mutationState.data?.response?.ok) {
      setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 2000);
    }
  }, [mutationState.data]);

  /**
   * Get any mutation of form errors and set to error state
   */
  useEffect(() => {
    setFormErrors(null);

    let errors: Array<string> = [];

    if (mutationState.data?.response?.errors?.nonFieldErrors) {
      errors = [
        ...errors,
        ...mutationState.data.response.errors.nonFieldErrors,
      ];
    }

    if (formError) {
      errors = [...errors, ...[formError]];
    }

    if (errors.length > 0) {
      setFormErrors(errors);
    }
  }, [mutationState.data, formError]);

  /**
   * Set has password state, we're looking for inputs for both password fields
   */
  useEffect(() => {
    setHasPassword(!!(formData.newPassword1 && formData.newPassword2));
  }, [formData]);

  /**
   * Set can submit state, we're looking for valid inputs for both password fields
   */
  useEffect(() => {
    const canSubmit = !(
      !hasPassword ||
      mutationState.data?.response?.errors ||
      formError ||
      formErrors ||
      !isValidPassord(formData.newPassword1) ||
      !isValidPassord(formData.newPassword2)
    );
    setCanSubmit(canSubmit);
  }, [hasPassword, mutationState.data, formError, formErrors, formData]);

  /**
   * On input change, reset any form data and reset form data
   * @param event
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    mutationState.reset();
    setFormError(null);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  /**
   * Handle form submit, resetting errors, checking passwords and calling parent
   * @param event
   * @returns
   */
  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutationState.reset();
    setFormError(null);

    // Passwords do not match
    if (!isPasswordMatch(formData.newPassword1, formData.newPassword2)) {
      setFormError(INVALID_PASSWORD_CONFIRMATION);
      return;
    }

    onSubmit(formData);
  };

  /**
   * Render page level error
   * @returns ReactElement
   */
  const renderError = (): ReactElement => (
    <>
      <p className="mb-2 font-medium">{errorMessage}</p>
      <p>
        Please try again, or contact our Customer Support team on{" "}
        {AHAUZ_PHONE_NUMBER}.
      </p>
    </>
  );

  /**
   * Render create account (set password) form
   * @returns ReactElement
   */
  const renderSetPasswordForm = (): ReactElement => (
    <Form
      onSubmit={handleFormSubmit}
      errors={formErrors}
      canSubmit={canSubmit}
      submitCTA={submitCTA}
      submitting={mutationState.loading}
    >
      <>
        <FormInputPassword
          id="newPassword1"
          onChange={handleInputChange}
          error={mutationState.data?.response?.errors?.newPassword1?.[0]}
        />
        <FormInputPassword
          id="newPassword2"
          label="Confirm Password"
          onChange={handleInputChange}
          validate={false}
          error={mutationState.data?.response?.errors?.newPassword2?.[0]}
        />
      </>
    </Form>
  );

  const renderContainer = (): ReactElement => {
    if (loading) {
      return <Loading />;
    }
    if (!(error || mutationState.error) && ok) {
      return renderSetPasswordForm();
    }
    return renderError();
  };

  if (mutationState.data?.response?.ok) {
    return onSuccess();
  }

  return <PageContainer>{renderContainer()}</PageContainer>;
}
