import LogoIconDark from "components/Image/Logo/IconDark";
import { WEB_URLS } from "settings";
import { classNames } from "utils/helpers";

type Props = {
  title?: string;
  copy?: JSX.Element | string;
  children?: JSX.Element;
  logo?: boolean;
  wide?: boolean;
};

export default function PageDialogScreen({
  title,
  copy,
  children,
  logo = true,
  wide = false,
}: Props) {
  const navigateToWebHome = () => (window.location.href = WEB_URLS.HOME);

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="px-2 sm:mx-auto sm:w-full sm:max-w-xl">
        {logo && <LogoIconDark onClick={navigateToWebHome} />}
        {title && <h1 className="mt-6 text-center text-4xl">{title}</h1>}
        {copy && (
          <p className="mt-3 text-center text-base text-brand-copy-light">
            {copy}
          </p>
        )}
      </div>
      <div
        className={classNames(
          logo || title ? "mt-8" : "",
          wide ? "sm:max-w-3xl" : "sm:max-w-md",
          "px-2 sm:mx-auto sm:w-full sm:px-0"
        )}
      >
        {children}
      </div>
    </div>
  );
}
