export const STEPS = {
  1: {
    key: "property",
    formId: "form-get-started-property",
  },
  2: {
    key: "account",
    formId: "form-get-started-account",
  },
};

export const PROPERTY_TYPE_HOUSE = "house";
export const PROPERTY_TYPE_FLAT = "flat";
export const PROPERTY_TYPE_BUNGALOW = "bungalow";

export const PROPERTY_TYPES = [
  { name: "House", value: "house" },
  { name: "Flat", value: "flat" },
  { name: "Bungalow", value: "bungalow" },
];

export const HOUSE_TYPES = [
  { name: "Detached", value: "detached" },
  { name: "Semi-detached", value: "semidetached" },
  { name: "Mid-terrace", value: "midterrace" },
  { name: "End-terrace", value: "endterrace" },
];

export const FLAT_TYPES = [
  { name: "Purpose build", value: "purposebuild" },
  { name: "Converted", value: "converted" },
];

export const BEDROOM_NUMBERS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const CUSTOMER_TITLE_TYPES = [
  { name: "Mr", value: "mr" },
  { name: "Mrs", value: "mrs" },
  { name: "Miss", value: "miss" },
  { name: "Ms", value: "ms" },
  { name: "Dr", value: "dr" },
];

export const GET_STARTED_COPY = {
  title: "Get Started",
  subtitle: "Tell us a bit about yourself and your property.",
};
