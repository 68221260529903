import * as yup from "yup";

export const VALIDATION_REQUIRED = "This field is required";
export const VALIDATION_EMAIL = "Please input a valid email address";
export const VALIDATION_PHONE_NUMBER = "Please input a valid phone number";

export const SCHEMA_USER = {
  firstName: yup.string().required(VALIDATION_REQUIRED),
  lastName: yup.string().required(VALIDATION_REQUIRED),
  email: yup.string().email(VALIDATION_EMAIL).required(VALIDATION_REQUIRED),
  phoneNumber: yup
    .string()
    .matches(/^[0-9 +]+$/, VALIDATION_PHONE_NUMBER)
    .min(6, VALIDATION_PHONE_NUMBER)
    .required(VALIDATION_REQUIRED),
};

export const validateConfirmPassword = (passwordField: string) =>
  yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent[passwordField] === value;
    });
