import IconAhauz from "components/Image/Icons/Ahauz";
import IconBank from "components/Image/Icons/Bank";
import IconEquity from "components/Image/Icons/Equity";
import IconProperty from "components/Image/Icons/Property";
import { useUserStore } from "store/User";

/**
 * Property stat types
 */
const STATS = {
  propertyValue: {
    icon: <IconProperty />,
    getTitle: () => {
      return "Estimated Property value";
    },
  },
  equity: {
    index: 0,
    icon: <IconEquity />,
    color: "#FF8A00",
    getTitle: () => {
      return "Estimated home equity";
    },
  },
  mortgage: {
    index: 1,
    icon: <IconBank />,
    color: "#00DEA3",
    getTitle: () => {
      const { COPY } = useUserStore.getState().userCountry;
      return COPY.VALUE_LABELS.MORTGAGE_BALANCE;
    },
  },
  secondMortgage: {
    index: 2,
    icon: <IconBank fill="#FCD341" />,
    color: "#FCD341",
    getTitle: () => {
      return "Second Mortgage";
    },
  },
  ahauzLoan: {
    index: 2,
    icon: <IconAhauz />,
    color: "#FCD341",
    getTitle: () => {
      return "Ahauz Loan";
    },
  },
};

/**
 * Return second mortgage stat type by secondMortgageIsAhauzLoan flag
 * @param equityCheck EquityCheckType
 * @returns STAT[]
 */
export const getSecondMortgageStatType = (
  isAhauzLoan: boolean | null | undefined
) => {
  if (isAhauzLoan) {
    return STATS.ahauzLoan;
  }
  return STATS.secondMortgage;
};

/**
 * If a percentage value to be rendered in the chart is negative, return 0
 * @param value
 * @returns number
 */
export const getStatChartValue = (value: number) => {
  if (value && value < 0) {
    return 0;
  }
  return value;
};

export default STATS;
