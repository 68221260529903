type Props = {
  error?: string;
};

export default function FormInputError({ error }: Props) {
  if (error) {
    return <p className="ml-0 mt-2 text-sm text-red-800">{error}</p>;
  }
  return <></>;
}
