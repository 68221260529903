import { DEFAULT_COUNTRY } from "country-config";

/**
 * Convert an input value to a float
 */
export const inputToFloat = (input: any) => {
  if (input) {
    try {
      return parseFloat(input.replaceAll(",", ""));
    } catch (error) {
      return input;
    }
  }
  return input;
};

/**
 * Return value in money format
 */
export const asCurrency = (
  value: any,
  countryCode: string = DEFAULT_COUNTRY,
  allowZero: boolean = false
) => {
  if (allowZero && value === 0) {
    return "0";
  }
  if (value) {
    const MoneyFormat = new Intl.NumberFormat(`en-${countryCode}`, {
      maximumFractionDigits: 2,
    });
    return MoneyFormat.format(value);
  }
  return "";
};
