import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { MUTATION_REGISTER_CUSTOMER_ACCOUNT } from "api/mutations/account";

import { trackPage } from "analytics";
import AlertError from "components/Alert/Error";
import FormErrors from "components/Form/Errors";
import FormInputCheckbox from "components/Form/Input/CheckBox";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputEmail from "components/Form/Input/Email";
import FormInputPassword from "components/Form/Input/Password";
import FormInputRadioGroup from "components/Form/Input/RadioGroup";
import FormInputSplitDate from "components/Form/Input/SplitDate";
import FormInputText from "components/Form/Input/Text";
import ProgressBar from "components/GetStarted/ProgressBar";
import useLeadGens from "hooks/useLeadGens";
import StepActions from "pages/GetStarted/components/StepActions";
import { CUSTOMER_TITLE_TYPES, STEPS } from "pages/GetStarted/consts";
import { getAccountSchema } from "pages/GetStarted/schema";
import { FormInputsAccount } from "pages/GetStarted/types";
import { AHAUZ_PHONE_NUMBER } from "settings";
import { useGetStartedStore } from "store/GetStarted";
import { useUserStore } from "store/User";
import { isEmptyObject } from "utils/helpers";

declare global {
  interface Window {
    cscertify: any;
    ContactStateCertify: any;
  }
}

export default function StepTwo() {
  const STEP_ID = 2;
  const STEP_KEY = STEPS[STEP_ID].key;
  const FORM_ID = STEPS[STEP_ID].formId;

  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    COPY,
    ASK_LOAN_QUESTIONS,
    CURRENCY_SYMBOL,
    COUNTRY_CODE,
    REQUIRE_PHONE_NUMBER,
    REQUIRE_CUSTOMER_TITLE,
    REQUIRE_SIGNUP_DATASET,
    REQUIRE_PROPERTY_DETAILS,
    LINKS,
    LEAD_GENS,
  } = useUserStore((state) => state.userCountry);

  const submission = useGetStartedStore((state) => state.submission);

  const updateStepPercent = useGetStartedStore(
    (state) => state.updateStepPercent
  );
  const updateSubmission = useGetStartedStore(
    (state) => state.updateSubmission
  );

  const navigate = useNavigate();

  const [registerCustomer, { error: mutationError, data: mutationData }] =
    useMutation(MUTATION_REGISTER_CUSTOMER_ACCOUNT);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<FormInputsAccount>({
    resolver: yupResolver(
      getAccountSchema(
        REQUIRE_PHONE_NUMBER,
        REQUIRE_CUSTOMER_TITLE,
        REQUIRE_SIGNUP_DATASET
      )
    ),
    defaultValues: {
      ...{
        marketingOptin: true,
      },
      ...submission.account,
      ...{
        password1: "",
        password2: "",
      },
    },
  });

  const formErrors = mutationData?.response?.errors;
  const hasErrors =
    formErrors ||
    mutationError ||
    (mutationData?.response?.ok === false && !formErrors);

  const title = watch("title");
  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const email = watch("email");
  const dateOfBirth = watch("dateOfBirth");
  const password1 = watch("password1");

  const fieldCompleteCount: number = [
    title,
    firstName,
    lastName,
    email,
    dateOfBirth,
    password1,
  ].filter((value) => value).length;

  const totalQuestions = 6;
  const stepPercent = (fieldCompleteCount / totalQuestions) * 100;

  useEffect(() => {
    trackPage("Get Started: Step 2");
  }, []);

  useLeadGens(LEAD_GENS);

  /**
   * Can only access this page if property form complete
   */
  useEffect(() => {
    if (isEmptyObject(submission[STEPS[1].key])) {
      return navigate("../");
    }
  }, [submission, navigate]);

  /**
   * Can only access this page if property form complete
   */
  useEffect(() => {
    if (mutationData?.response?.ok) {
      return navigate("../complete", { state: { submission: true } });
    }
    setSubmitLoading(false);
  }, [mutationData, navigate]);

  useEffect(() => {
    setSubmitLoading(false);
  }, [mutationError]);

  /**
   * Update step percent
   * */
  useEffect(() => {
    updateStepPercent(STEP_ID, stepPercent);
  }, [stepPercent, updateStepPercent]);

  const goBack = () => {
    updateSubmission(STEP_KEY, getValues());
    return navigate("../");
  };

  const onSubmit = async (data: any) => {
    setSubmitLoading(true);

    const _submission = updateSubmission(STEP_KEY, data);

    const _submissionData = {
      ..._submission.property,
      ..._submission.account,
      ...{
        marketingOptin:
          _submission.account.marketingOptin === ""
            ? false
            : _submission.account.marketingOptin,
        addressCountry: COUNTRY_CODE,
        leads: {
          contactState: {
            claimCertUrl: null,
          },
        },
      },
    };

    // Force confirm password
    _submissionData.password2 = _submissionData.password1;

    // We dont need to send the manualAddress flag
    delete _submissionData.manualAddress;

    // We don't need to send the date parts
    delete _submissionData.dateOfBirthPartDay;
    delete _submissionData.dateOfBirthPartMonth;
    delete _submissionData.dateOfBirthPartYear;

    if (LEAD_GENS && window.cscertify) {
      try {
        const CSClaimCert = await window.cscertify("send", {
          form_id: FORM_ID,
        });
        _submissionData.leads.contactState.claimCertUrl = CSClaimCert;
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    registerCustomer({
      variables: {
        account: _submissionData,
      },
    });
  };

  const renderSignUpDisclaimer = () => {
    let copy = COPY.SIGN_UP_DISCLAIMER;
    copy = copy.replace(
      "[TERMS_LINK]",
      `<a href=${LINKS.TERMS} title="Terms and Conditions" target="_blank" rel="noreferrer">Terms and Conditions</a>`
    );
    copy = copy.replace(
      "[PRIVACY_POLICY_LINK]",
      `<a href=${LINKS.PRIVACY} title="Privacy Policy" target="_blank" rel="noreferrer">Privacy Policy</a>`
    );
    return copy;
  };

  return (
    <>
      <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)} noValidate>
        <ProgressBar />

        <div className="mt-6 grid grid-cols-6 gap-4 sm:mt-8 sm:gap-6">
          {REQUIRE_CUSTOMER_TITLE && (
            <div className="col-span-6">
              <FormInputRadioGroup
                id="title"
                label="Title"
                options={CUSTOMER_TITLE_TYPES}
                error={errors?.title?.message}
                control={control}
              />
            </div>
          )}
          <div className="col-span-6 sm:col-span-3">
            <FormInputText
              id="firstName"
              label="First name"
              register={register}
              error={errors?.firstName?.message}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <FormInputText
              id="lastName"
              label="Last name"
              register={register}
              error={errors?.lastName?.message}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <FormInputEmail
              id="email"
              label="Email"
              register={register}
              error={errors?.email?.message}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <FormInputText
              id="phoneNumber"
              label="Phone number"
              register={register}
              error={errors?.phoneNumber?.message}
            />
          </div>

          {ASK_LOAN_QUESTIONS && (
            <>
              <div className="col-span-6 sm:col-span-3">
                <FormInputCurrency
                  id="propertyValue"
                  label="Estimated market value of your property"
                  register={register}
                  currency={CURRENCY_SYMBOL}
                  countryCode={COUNTRY_CODE}
                  error={errors?.propertyValue?.message}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <FormInputCurrency
                  id="mortgageBalance"
                  label="Estimate of your outstanding home loan"
                  register={register}
                  currency={CURRENCY_SYMBOL}
                  countryCode={COUNTRY_CODE}
                  error={errors?.mortgageBalance?.message}
                  allowZero
                />
              </div>
            </>
          )}

          <div className="col-span-6 sm:col-span-3">
            <FormInputSplitDate
              id="dateOfBirth"
              label="Date of birth"
              register={register}
              error={{
                root: errors?.dateOfBirth?.message,
                day: errors?.dateOfBirthPartDay?.message,
                month: errors?.dateOfBirthPartMonth?.message,
                year: errors?.dateOfBirthPartYear?.message,
              }}
              setValue={setValue}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <FormInputPassword
              id="password1"
              register={register}
              error={errors?.password1?.message}
              helperText="Minimum length 8, one uppercase, one number"
            />
          </div>
          <div className="col-span-6">
            <p
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: renderSignUpDisclaimer() }}
            ></p>
            <div className="mt-4 sm:mt-6">
              <FormInputCheckbox
                id="marketingOptin"
                label="Are you happy to receive marketing communications from us?"
                control={control}
                small
              />
            </div>
          </div>
        </div>

        {hasErrors && (
          <div className="mt-8">
            {formErrors ? (
              <FormErrors errors={formErrors} />
            ) : (
              <AlertError title="Error creating your account">
                <p>{`Sorry, we can't create your account right now. Please try again or contact our Customer Support team on ${AHAUZ_PHONE_NUMBER}.`}</p>
              </AlertError>
            )}
          </div>
        )}

        <StepActions
          goBack={REQUIRE_PROPERTY_DETAILS ? goBack : undefined}
          loading={submitLoading}
          labelNext="Finish"
        />
      </form>
    </>
  );
}
