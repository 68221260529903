import { useSearchParams, Navigate, Outlet } from "react-router-dom";
import { passOnSearchParams } from "routes/utils";

import { APP_URLS } from "settings";

/**
 * Route authenticated users to their Dashboard if logged in
 */
export default function RouteUnauthenticated({
  authenticated,
}: {
  authenticated: boolean;
}) {
  const [searchParams] = useSearchParams();

  if (authenticated) {
    const navigateTo = `${APP_URLS.DASHBOARD}${passOnSearchParams(
      searchParams
    )}`;
    return <Navigate replace to={navigateTo} />;
  }
  return <Outlet />;
}
