import AccountVerification from "pages/Account/AccountVerification";
import Dashboard from "pages/Dashboard";
import { RouteType } from "routes/types";
import { APP_URLS } from "settings";

const AUTHENTICATED_ROUTES: RouteType[] = [
  {
    path: APP_URLS.DASHBOARD,
    component: Dashboard,
  },
  {
    path: APP_URLS.ACCOUNT.ACCOUNT_VERIFICATION,
    component: AccountVerification,
  },
];

export default AUTHENTICATED_ROUTES;
