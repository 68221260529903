export default function IconProperty() {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9903 8.80488L19.3242 0.850791C17.6589 -0.283597 15.4698 -0.283597 13.8062 0.850791L2.14008 8.80488C0.80158 9.71743 0 11.2334 0 12.8527V27.1014C0 29.8065 2.19336 32 4.89861 32H10.464V22.4917C10.464 21.236 11.4815 20.217 12.7387 20.217H20.3925C21.6481 20.217 22.6672 21.2345 22.6672 22.4917V32H28.2325C30.9376 32 33.1311 29.8066 33.1311 27.1014V12.8527C33.1297 11.2332 32.328 9.71728 30.9911 8.80488H30.9903Z"
        fill="#0D493B"
      />
    </svg>
  );
}
