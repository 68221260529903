import mixpanel from "mixpanel-browser";
import { IS_REAL_ENV } from "settings";

const hotjar = (window as any).hj;

/**
 * Init Anlytics tracking providers
 */
export const initAnalytics = () => {
  // Set up Mixpanel
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });

    // Disable mixpanel on dev unless needed for testing
    if (!IS_REAL_ENV) {
      mixpanel.disable();
    }
  }
};

/**
 * Trigger event track
 * @param event string
 * @param params Object
 */
export const trackEvent = (event: string, params = {}) => {
  mixpanel.track(event, params);

  if (hotjar) {
    const hj_event = event
      .replaceAll(":", "")
      .replaceAll("/", "_")
      .replaceAll(" ", "_");
    hotjar("event", hj_event);
  }
};

/**
 * Trigger page track
 * @param event string
 * @param params Object
 */
export const trackPage = (page: string, params = {}) => {
  const pageEvent = `Page: ${page}`;
  mixpanel.track(pageEvent, params);
};

/**
 * Identify a User for tracking data
 */
export const identifyTrackingUser = (
  userId: string,
  properties: any = null
) => {
  mixpanel.identify(userId);

  if (properties) {
    mixpanel.people.set(properties);
  }

  if (hotjar) {
    hotjar("identify", userId, properties);
  }
};

/**
 * Reset a unique User for tracking data
 */
export const resetTrackingUser = () => {
  mixpanel.reset();
};
