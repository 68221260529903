import AlertError from "components/Alert/Error";
import EquityChart from "components/Dashboard/Stats/Chart";
import Wrapper from "components/Dashboard/Wrapper";
import Appointments from "pages/Dashboard/components/Appointments";
import { CHECK_STATUS } from "pages/Dashboard/components/EquityCheck/consts";
import EquityCheckSuccess from "pages/Dashboard/components/EquityCheck/Success";
import EquityCheckSubmitted from "pages/Dashboard/components/EquityCheck/Submitted";
import EquityCheckFail from "pages/Dashboard/components/EquityCheck/Fail";
import { useUserStore } from "store/User";
import { AHAUZ_PHONE_NUMBER } from "settings";

export default function EquityCheck() {
  const equityCheck = useUserStore((state) => state.equityCheck);
  const eligibleForCall = useUserStore((state) => state.eligibleForCall);
  const eligibleForLead = useUserStore((state) => state.eligibleForLead);
  const equityCheckIsSuccess = useUserStore(
    (state) => state.equityCheckIsSuccess
  );

  const renderError = () => (
    <Wrapper>
      <AlertError>
        <p className="text-base">
          {`Sorry, we can not load your data at this time. Please try again or contact our Customer Support team on ${AHAUZ_PHONE_NUMBER}.`}
        </p>
      </AlertError>
    </Wrapper>
  );

  if (equityCheck?.checkStatus === CHECK_STATUS.SUCCESS && !equityCheck.live) {
    return renderError();
  }

  const renderDashContent = () => {
    if (equityCheck?.checkStatus === CHECK_STATUS.FAIL) {
      return <EquityCheckFail />;
    }
    if (equityCheck?.checkStatus === CHECK_STATUS.SUCCESS && equityCheck.live) {
      return <EquityCheckSuccess equityCheck={equityCheck} />;
    }
    return <EquityCheckSubmitted />;
  };

  return (
    <>
      {equityCheckIsSuccess ? (
        <div className="-mb-10 overflow-scroll pl-0 pr-0 lg:2xl:container lg:relative lg:mx-auto lg:mb-0 lg:overflow-visible lg:pl-8">
          <div className="lg:relative lg:left-[calc(-50vw+50%)] lg:flex lg:w-screen lg:gap-12 lg:pl-[calc((100vw-1536px)/2)]">
            <div className="flex h-auto w-[500px] max-w-md items-center max-lg:hidden">
              <EquityChart
                chartID="propertyChartBody"
                equityCheck={equityCheck}
              />
            </div>
            <div className="md:flex md:items-center md:overflow-scroll">
              {renderDashContent()}
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-3 mt-4 md:mb-6 md:mt-9">{renderDashContent()}</div>
      )}
      {eligibleForCall && eligibleForLead && <Appointments />}
    </>
  );
}
