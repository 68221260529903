import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { MUTATION_UPDATE_ACCOUNT } from "api/mutations/account";

import AlertError from "components/Alert/Error";
import Card from "components/Card";
import CardBody from "components/Card/Body";
import CardFooter from "components/Card/Footer";
import Container from "components/Dashboard/Container";
import CTAButton from "components/CTA/Button";
import FormInputEmail from "components/Form/Input/Email";
import FormInputText from "components/Form/Input/Text";
import Loading from "components/Loading";
import {
  COPY_FORM_ERROR_TRY_AGAIN,
  COPY_FORM_SUCCESS_DETAILS_SAVED,
} from "pages/Account/AccountProfile/consts";
import * as consts from "pages/Account/AccountProfile/components/PersonalInformation/consts";
import { getAccountSchema } from "pages/Account/AccountProfile/components/PersonalInformation/schema";
import { FormInputs } from "pages/Account/AccountProfile/components/PersonalInformation/types";
import { useUserStore } from "store/User";
import { useNotificationStore } from "store/Notifications";
import FormInputCurrency from "components/Form/Input/Currency";
import { inputToFloat } from "utils/currency";
import { COPY_FORM_ERROR_CONTACT_SUPPORT } from "utils/copy";
import FormInputCheckbox from "components/Form/Input/CheckBox";

export default function PersonalInformation() {
  const [hasInit, setHasInit] = useState(false);

  const user = useUserStore((state) => state.user);
  const { CURRENCY_SYMBOL, COUNTRY_CODE, REQUIRE_PHONE_NUMBER } = useUserStore(
    (state) => state.userCountry
  );

  const setUser = useUserStore((state) => state.setUser);
  const createNotification = useNotificationStore(
    (state) => state.createNotification
  );

  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(getAccountSchema(REQUIRE_PHONE_NUMBER)),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.detail?.phoneNumber,
      dateOfBirth: user?.detail?.dateOfBirth,
      householdIncome: user?.detail?.householdIncome,
      marketingOptin: user?.detail?.marketingOptin,
    },
  });

  const [
    updateAccount,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_ACCOUNT);

  const hasErrors = Object.keys(errors).length > 0;
  const success = mutationData?.response?.ok && !hasErrors;

  /**
   * Handle setting default values on load and after submit
   */
  useEffect(() => {
    if (user) {
      const _user = {
        ...user,
        ...user.detail,
      };
      if (hasInit) {
        reset(_user, { keepErrors: true, keepValues: true });
      } else {
        reset(_user);
        setHasInit(true);
      }
    }
  }, [user, hasInit, reset]);

  /**
   * Handle submission errors
   */
  useEffect(() => {
    if (!mutationData?.response?.ok && mutationData?.response?.errors) {
      const serverErrors: FormInputs = mutationData.response.errors;

      for (let [key, errors] of Object.entries(serverErrors)) {
        setError(key as keyof FormInputs, {
          type: "server",
          message: errors[0],
        });
      }
    }

    if (mutationData?.response?.customer) {
      setUser(mutationData.response.customer);
    }

    if (success) {
      createNotification({
        title: consts.COPY_PERSONAL_INFORMATION_SUCCESS_TITLE,
        description: COPY_FORM_SUCCESS_DETAILS_SAVED,
      });
    }
  }, [mutationData, setUser, setError, createNotification, success]);

  useEffect(() => {
    if (mutationError) {
      createNotification({
        type: "error",
        title: "Error updating your personal information",
        description: COPY_FORM_ERROR_CONTACT_SUPPORT,
      });
    }
  }, [mutationError, createNotification]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const _submissionData = {
      ...data,
      ...{
        householdIncome: data.householdIncome
          ? inputToFloat(data.householdIncome)
          : null,
      },
    };

    updateAccount({
      variables: {
        input: {
          firstName: _submissionData.firstName,
          lastName: _submissionData.lastName,
          email: _submissionData.email,
          phoneNumber: _submissionData.phoneNumber,
          dateOfBirth: _submissionData.dateOfBirth,
          householdIncome: _submissionData.householdIncome,
          marketingOptin: _submissionData.marketingOptin,
        },
      },
    });
  };

  return (
    <Container>
      <Card
        title={consts.COPY_PERSONAL_INFORMATION_TITLE}
        subtitle="Check your your contact information is up to date. If your name has changed please contact us at <a href='mailto:info@ahauz.com'>info@ahauz.com</a>."
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardBody>
            <>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <FormInputText
                    id="firstName"
                    label="First name"
                    register={register}
                    error={errors?.firstName?.message}
                    disabled
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <FormInputText
                    id="lastName"
                    label="Last name"
                    register={register}
                    error={errors?.lastName?.message}
                    disabled
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <FormInputEmail
                    id="email"
                    label="Email"
                    register={register}
                    error={errors?.email?.message}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <FormInputText
                    id="phoneNumber"
                    label="Phone number"
                    register={register}
                    error={errors?.phoneNumber?.message}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <FormInputText
                    type="date"
                    id="dateOfBirth"
                    label="Date of Birth"
                    register={register}
                    error={errors?.dateOfBirth?.message}
                    disabled
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <FormInputCurrency
                    id="householdIncome"
                    label="Annual Gross Household Income (Yearly, not Monthly)"
                    register={register}
                    defaultVal={user?.detail?.householdIncome}
                    currency={CURRENCY_SYMBOL}
                    countryCode={COUNTRY_CODE}
                    error={errors?.householdIncome?.message}
                  />
                </div>
                <div className="col-span-6">
                  <FormInputCheckbox
                    id="marketingOptin"
                    label="Are you happy to receive marketing communications from us?"
                    control={control}
                  />
                </div>
              </div>
              {hasErrors && (
                <div className="mt-5">
                  <AlertError title="Error updating your personal information">
                    <p>{COPY_FORM_ERROR_TRY_AGAIN}</p>
                  </AlertError>
                </div>
              )}
            </>
          </CardBody>
          <CardFooter>
            {mutationLoading ? (
              <div className="mr-3 inline-block">
                <Loading />
              </div>
            ) : (
              <CTAButton type="submit" label="Save" />
            )}
          </CardFooter>
        </form>
      </Card>
    </Container>
  );
}
