import { gql } from "@apollo/client";

export const QUERY_APPOINTMENTS = gql`
  query CustomerAppointments {
    customerAppointments {
      id
      slug
      title
      startTime
      cancelId
      rescheduleUrl
    }
  }
`;
