import { gql } from "@apollo/client";

export const FRAGMENT_BASE_RESPONSE_FIELDS = gql`
  fragment BaseResponseFields on BaseResponseType {
    ok
    message
    errors
  }
`;

export const FRAGMENT_RESPONSE_FIELDS = gql`
  fragment ResponseFields on ResponseType {
    ok
    message
    errors
  }
`;

export const FRAGMENT_CUSTOMER_TYPE = gql`
  fragment CustomerFields on CustomerType {
    id
    email
    firstName
    lastName
    fullName
    isVerified
    callWindowOpen
    detail {
      id
      phoneNumber
      status
      dateOfBirth
      householdIncome
      marketingOptin
      age
    }
  }
`;

export const FRAGMENT_EQUITYCHECK_TYPE = gql`
  fragment EquityCheckFields on EquityCheckType {
    id
    addressTownOrCity
    addressPostcode
    addressCountry
    checkStatus
    dataAccuracyDate
    shortAddress
    productRemortgage
    productBuyProperty
    productEquityLoan
    productDebtConsolidation
    productEquityRelease
    productMortgageProtection
    live {
      equity
      creditCheckMortgageBalance
      creditCheckCreditScore
      hasCreditIssues
      secondMortgageBalance
      secondMortgageIsAhauzLoan
      valuationConfidenceLevel
      propertyValuation
      internalPropertyValuation
      propertyMortgagePercent
      propertySecondMortgagePercent
      propertyEquityPercent
    }
  }
`;

export const FRAGMENT_VERIFICATION_CHECK_TYPE = gql`
  fragment VerificationCheckFields on VerificationQuestionType {
    mortgageQa {
      question
      options {
        balanceAmount
        startedAt
        startYear
      }
    }
    lastMortgageYearQa {
      question
      options
    }
  }
`;
