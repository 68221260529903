import Wrapper from "components/Dashboard/Wrapper";

export default function EquityCheckFail() {
  return (
    <Wrapper>
      <>
        <h2 className="mb-4 text-3xl">
          Sorry, we're unable to calculate your Home Equity
        </h2>
        <p className="mb-2 text-base">
          We've reviewed your information and unfortunately we cannot determine
          your equity position at this time.
        </p>
        <p className="mb-2 text-base">
          This is typically because we aren't able to access some of the
          information we require to calculate your home equity.
        </p>
        <p className="mb-2 text-base">
          If you would like to discuss this, here's a few ways you can get in
          touch:
        </p>
        <ul className="list-inside list-disc text-base">
          <li>Call: 0330 818 7020 (Monday - Friday 9am to 5pm)</li>
          <li>
            Email: <a href="mailto:enquiries@ahauz.com">enquiries@ahauz.com</a>
          </li>
        </ul>
      </>
    </Wrapper>
  );
}
