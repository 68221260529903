export default function Failed() {
  return (
    <div className="px-0 py-0 text-center text-lg md:px-6">
      <p className="mb-2">We're sorry but we couldn't verify your details.</p>
      <p>
        We have sent you an email with further instructions to complete your
        verification. Please check your inbox.
      </p>
    </div>
  );
}
