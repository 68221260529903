import { gql } from "@apollo/client";

import {
  FRAGMENT_BASE_RESPONSE_FIELDS,
  FRAGMENT_CUSTOMER_TYPE,
  FRAGMENT_EQUITYCHECK_TYPE,
} from "api/fragments";

export const MUTATION_CREATE_CUSTOMER_ACCOUNT = gql`
  mutation CustomerCreate($account: CreateAccountFromTokenInput!) {
    response: accountCustomerCreate(account: $account) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    accountLogin(username: $username, password: $password) {
      email
      type
    }
  }
`;

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    accountLogout
  }
`;

export const MUTATION_FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!, $userType: String!) {
    accountForgotPassword(email: $email, userType: $userType) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_SET_USER_PASSWORD = gql`
  mutation ResetPassword($userPassword: SetUserPasswordInput!) {
    response: accountResetPassword(userPassword: $userPassword) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_UPDATE_ACCOUNT = gql`
  mutation CustomerUpdate($input: CustomerInput!) {
    response: accountCustomerUpdate(input: $input) {
      ...BaseResponseFields
      customer {
        ...CustomerFields
        equityCheck {
          ...EquityCheckFields
        }
      }
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
  ${FRAGMENT_CUSTOMER_TYPE}
  ${FRAGMENT_EQUITYCHECK_TYPE}
`;

export const MUTATION_UPDATE_ACCOUNT_META = gql`
  mutation CustomerUpdateMeta($input: CustomerMetaInput!) {
    response: accountCustomerUpdateMeta(input: $input) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    response: accountChangePassword(input: $input) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_REGISTER_CUSTOMER_ACCOUNT = gql`
  mutation RegisterCustomer($account: CustomerRegisterInput!) {
    response: accountCustomerRegister(account: $account) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_VERIFY_CUSTOMER_ACCOUNT = gql`
  mutation CustomerVerify(
    $lastMortgageYearInput: LastMortgageYearInput
    $mortgageInput: [MortgageInput!]
  ) {
    response: accountCustomerVerify(
      lastMortgageYearInput: $lastMortgageYearInput
      mortgageInput: $mortgageInput
    ) {
      ok
      manual
      message
      mortgageQa {
        question
        options {
          balanceAmount
          startedAt
          startYear
        }
      }
    }
  }
`;
