import { gql } from "@apollo/client";

import { FRAGMENT_BASE_RESPONSE_FIELDS } from "api/fragments";

export const MUTATION_CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($slug: String!, $cancelId: String!) {
    response: meetingCancelAppointment(
      meetingSlug: $slug
      cancelId: $cancelId
    ) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;
