import { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { trackEvent } from "analytics";

import { MUTATION_UPDATE_ACCOUNT_META } from "api/mutations/account";

export default function EventListeners() {
  const [updateAccountMeta] = useMutation(MUTATION_UPDATE_ACCOUNT_META);

  useEffect(() => {
    const handleEventMessage = (event: MessageEvent) => {
      // Catch HubSpot meeting bookings
      if (
        event.origin === "https://meetings-eu1.hubspot.com" &&
        event?.data?.meetingBookSucceeded &&
        event?.data?.meetingsPayload?.userSlug
      ) {
        trackEvent(
          `Appointments: Booked: ${event.data.meetingsPayload.userSlug}`
        );
        updateAccountMeta({
          variables: {
            input: {
              meta: {
                initial_meeting_booked: true,
              },
            },
          },
        });
      }
    };

    window.addEventListener("message", handleEventMessage);

    return () => window.removeEventListener("message", handleEventMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
