export default function IconArrowRight() {
  return (
    <svg
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12.9998L3.27802 -5.51569e-07L-1.46966e-07 3.33482L9.45865 13L-9.98858e-07 22.6652L3.27802 26L16 12.9998Z"
        fill="#0D493B"
      />
    </svg>
  );
}
