import { useState } from "react";
import { trackEvent } from "analytics";
import CRMDialog from "components/CRM/Dialog";
import { useUserStore } from "store/User";
import { CRM } from "settings";

export default function CRMNewAppointment({
  bookingLink,
  defaultOpen = false,
  closeAction,
  customAction,
  children,
}: {
  bookingLink?: string;
  defaultOpen?: boolean;
  customAction?: Function;
  closeAction?: Function;
  children?: JSX.Element;
}) {
  const user = useUserStore((state) => state.user);

  const [open, setOpen] = useState(defaultOpen);

  const openBooking = async () => {
    customAction && customAction();

    setOpen(true);

    let event = `Appointments: Open: ${
      bookingLink || CRM.APPOINTMENT_BOOKING_URL
    }`;
    event = event.replace("?embed=true", "");
    event = event.replace("https://meetings-eu1.hubspot.com/", "");

    trackEvent(event);
  };

  const handleClose = () => {
    setOpen(false);
    if (closeAction) {
      closeAction();
    }
  };

  let BOOKING_URL = bookingLink || CRM.APPOINTMENT_BOOKING_URL;
  if (user) {
    let _userEmail = user.email.replaceAll("+", "%2b");
    BOOKING_URL = `${BOOKING_URL}&firstName=${user.firstName}&lastName=${user.lastName}&email=${_userEmail}&phone=${user.detail?.phoneNumber}`;
  }

  return (
    <>
      {open && (
        <CRMDialog title="Book an appointment" onClose={handleClose}>
          <div
            style={{
              width: "345px",
              height: "586px",
              position: "relative",
              left: "-4px",
              top: "-14px",
            }}
          >
            <iframe
              title="hubspot"
              style={{
                width: "350px",
                height: "600px",
              }}
              src={BOOKING_URL}
            />
          </div>
        </CRMDialog>
      )}
      {children && <div onClick={openBooking}>{children}</div>}
    </>
  );
}
