import {
  useLocation,
  useSearchParams,
  Navigate,
  Outlet,
} from "react-router-dom";
import { passOnSearchParams } from "routes/utils";

import { APP_URLS } from "settings";

/**
 * Route authenticated users to requested page, else to Login page
 */
export default function RouteRequireAuthVerified({
  authenticated,
  verified,
}: {
  authenticated: boolean;
  verified: boolean;
}) {
  const [searchParams] = useSearchParams();
  let location = useLocation();

  if (!!(authenticated && !verified)) {
    const navigateTo = `${
      APP_URLS.ACCOUNT.ACCOUNT_VERIFICATION
    }${passOnSearchParams(searchParams)}`;
    return <Navigate to={navigateTo} state={{ from: location }} />;
  }

  if (!authenticated) {
    const navigateTo = `${APP_URLS.LOGIN}${passOnSearchParams(searchParams)}`;
    return <Navigate to={navigateTo} state={{ from: location }} />;
  }

  return <Outlet />;
}
