import { useId } from "react";

export default function IconTimeline() {
  const id = useId();

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="white" />
      <mask
        id={`mask0_${id}`}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <circle cx="15" cy="15" r="15" fill="white" />
      </mask>
      <g mask={`url(#mask0_${id})`}>
        <path
          opacity="0.5"
          d="M-0.470434 18.7477L-0.96875 19.9509V27.1516H34.91V8.94091L34.4117 8.72065C33.9134 8.50039 32.9168 8.05987 31.9201 7.89031C30.9235 7.72075 29.9269 7.82215 28.9302 8.82408C27.9336 9.82601 26.937 11.7285 25.9403 11.8143C24.9437 11.9001 23.9471 10.1692 22.9504 9.91122C21.9538 9.65327 20.9572 10.8682 19.9605 11.6126C18.9639 12.357 17.9673 12.6309 16.9706 12.4273C15.974 12.2237 14.9774 11.5425 13.9807 11.8548C12.9841 12.167 11.9875 13.4727 10.9908 14.2233C9.99421 14.974 8.99758 15.1696 8.00095 14.9125C7.00431 14.6553 6.00768 13.9453 5.01105 13.5064C4.01441 13.0674 3.01778 12.8995 2.02115 14.0188C1.02452 15.138 0.027883 17.5445 -0.470434 18.7477Z"
          fill={`url(#paint0_linear_${id}`}
        />
        <path
          d="M-0.96875 19.8848L-0.470433 18.6815C0.027883 17.4783 1.02452 15.0719 2.02115 13.9526C3.01778 12.8333 4.01441 13.0012 5.01105 13.4402C6.00768 13.8791 7.00431 14.5892 8.00095 14.8463C8.99758 15.1035 9.99421 14.9078 10.9908 14.1571C11.9875 13.4065 12.9841 12.1009 13.9807 11.7886C14.9774 11.4764 15.974 12.1575 16.9706 12.3611C17.9673 12.5647 18.9639 12.2909 19.9605 11.5465C20.9572 10.8021 21.9538 9.5871 22.9504 9.84506C23.9471 10.103 24.9437 11.8339 25.9403 11.7481C26.937 11.6623 27.9336 9.75985 28.9302 8.75792C29.9269 7.75599 30.9235 7.65458 31.9201 7.82415C32.9168 7.99371 33.9134 8.43423 34.4117 8.65449L34.91 8.87475"
          stroke="#0D493B"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="16.9706"
          y1="7.82373"
          x2="16.9706"
          y2="25.7759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05CD99" />
          <stop offset="1" stopColor="#05CD99" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
