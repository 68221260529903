import * as yup from "yup";

import { SCHEMA_USER, VALIDATION_REQUIRED } from "utils/validation";

export const getAccountSchema = (requirePhoneNumber: boolean) =>
  yup.object({
    ...SCHEMA_USER,
    ...{
      phoneNumber: requirePhoneNumber
        ? yup.string().required(VALIDATION_REQUIRED)
        : yup.string().optional().nullable(),
      dateOfBirth: yup.string().optional().nullable(),
      householdIncome: yup.string().optional().nullable(),
    },
  });
