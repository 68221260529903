import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

import { NotificationPropType } from "components/Notifications/types";

export default function Notification({
  notification,
  onDismiss,
}: NotificationPropType) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      handleDismiss();
    }, 6000);
  });

  const handleDismiss = () => {
    setShow(false);
    setTimeout(() => {
      onDismiss(notification.id);
    }, 100);
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-2 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {notification.type === "error" ? (
                <XCircleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              ) : (
                <CheckCircleIcon
                  className="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-base font-medium">{notification.title}</p>
              <p className="mt-1 text-base text-brand-copy-light">
                {notification.description}
              </p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex border-none bg-white text-gray-400 hover:text-gray-500 focus:ring-0"
                onClick={handleDismiss}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
