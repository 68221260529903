export default function IconEquity() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.69531V15.4992H25.3038C25.0513 10.758 21.2414 6.94779 16.5 6.69531Z"
        fill="#FF8D3B"
      />
      <path
        d="M16 0C7.16256 0 0 7.16384 0 16C0 24.8374 7.16256 32 16 32C24.8362 32 32 24.8374 32 16C32 7.16384 24.8362 0 16 0ZM16 26.3226C10.3088 26.3226 5.67744 21.6912 5.67744 16C5.67744 10.3088 10.3075 5.67744 16 5.67744C21.6912 5.67744 26.3226 10.3088 26.3226 16C26.3226 21.6912 21.6912 26.3226 16 26.3226Z"
        fill="#FF8D3B"
      />
    </svg>
  );
}
