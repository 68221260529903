import * as yup from "yup";

export default yup
  .object({
    phoneNumber: yup.string().required("Please provide your contact number"),
    product: yup
      .string()
      .required("Please tell us what you would like to discuss")
      .typeError("Please tell us what you would like to discuss"),
  })
  .required();
