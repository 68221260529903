import { gql } from "@apollo/client";

import {
  FRAGMENT_BASE_RESPONSE_FIELDS,
  FRAGMENT_EQUITYCHECK_TYPE,
} from "api/fragments";

export const MUTATION_UPDATE_EQUITY_CHECK = gql`
  mutation EquityCheckUpdate($input: EquityCheckInput!) {
    response: equitycheckUpdate(input: $input) {
      ...BaseResponseFields
      equityCheck {
        ...EquityCheckFields
      }
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
  ${FRAGMENT_EQUITYCHECK_TYPE}
`;
