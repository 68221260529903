import { useState } from "react";

import HeroWhite from "components/Image/Logo/HeroWhite";
import NavigationDesktop from "components/Navigation/Desktop";
import NavigationMobile from "components/Navigation/Mobile";
import { APP_URLS } from "settings";
import { useUserStore } from "store/User";

export default function AuthScreenBase({
  title,
  subtitle,
  signup,
  children,
}: {
  title?: string;
  subtitle?: string;
  signup?: boolean;
  children: JSX.Element;
}) {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const user = useUserStore((state) => state.user);
  const { COPY } = useUserStore((state) => state.userCountry);

  const toggleNav = () => (navOpen ? setNavOpen(false) : setNavOpen(true));

  const renderSignupOrLogin = () => {
    if (!user) {
      if (signup) {
        return (
          <p className="mb-2 mt-2">
            Looking to sign up?{" "}
            <a href={APP_URLS.GET_STARTED.ROOT}>Click here to Get Started</a>
          </p>
        );
      }

      return (
        <p className="mb-2 mt-2">
          Already have an account?{" "}
          <a href={APP_URLS.LOGIN}>Click here to log in</a>
        </p>
      );
    }
  };

  return (
    <>
      <NavigationMobile navOpen={navOpen} toggleNav={toggleNav} />

      <div className="min-h-full">
        <div className="brand-hero relative overflow-hidden">
          <NavigationDesktop navOpen={navOpen} toggleNav={toggleNav} />

          <header className="relative z-10 pb-4 pt-4 md:pb-14 md:pt-8">
            <div className="mx-auto max-w-2xl px-4 text-center text-base text-white 2xl:container sm:px-6 md:text-lg lg:px-8">
              <h1 className="mb-3 text-3xl font-semibold md:mb-6 md:text-5xl">
                {title}
              </h1>
              <p>{subtitle}</p>
              <p>{!signup && COPY.GET_STARTED}</p>
              {renderSignupOrLogin()}
            </div>
          </header>

          <div className="z-o absolute -top-1/3 flex w-full items-center justify-center overflow-hidden md:top-0">
            <HeroWhite />
          </div>
        </div>

        <main className="my-8 lg:overflow-visible">
          <div className="mx-auto px-4 py-0 pb-12 2xl:container sm:px-6 md:py-4 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-2xl ">
              <div className="flex flex-col space-y-8 md:space-y-10">
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
