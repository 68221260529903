import create from "zustand";
import { NotificationState } from "store/Notifications/types";

export const useNotificationStore = create<NotificationState>()((set) => ({
  notifications: [],
  createNotification: (notification: Object) =>
    set((state: any) => ({
      notifications: [
        {
          id: Date.now(),
          ...notification,
        },
        ...state.notifications,
      ],
    })),
  dismissNotification: (notificationID: any) =>
    set((state: any) => ({
      notifications: state.notifications.filter(
        (notification: any) => notification.id !== notificationID
      ),
    })),
}));
