import Wrapper from "components/Dashboard/Wrapper";
import EquityCheckActions from "components/EquityCheck/Actions";
import EquityCheckActionsTitle from "components/EquityCheck/Actions/Title";
import { useUserStore } from "store/User";

const ACTIONS_STYLE_DEFAULTS = "pb-10";
const ACTIONS_SCROLL_STYLE =
  "no-scroll-bar flex flex-row flex-nowrap space-x-4 overflow-y-scroll pr-5 pl-5";
const ACTIONS_GRID_STYLE =
  "md:grid md:gap-4 md:grid-cols-3 xl:grid-cols-4 md:space-x-0 md:overflow-y-visible md:pr-0 md:pl-0";

export default function TimelineEquityCTAs() {
  const equityCheck = useUserStore((state) => state.equityCheck);

  if (equityCheck) {
    return (
      <div>
        <Wrapper>
          <EquityCheckActionsTitle />
        </Wrapper>

        <div className="md:px-4 md:px-6 lg:px-8">
          <div
            className={`${ACTIONS_SCROLL_STYLE} ${ACTIONS_GRID_STYLE} ${ACTIONS_STYLE_DEFAULTS}`}
          >
            <EquityCheckActions equityCheck={equityCheck} />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
