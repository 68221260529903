import { CheckIcon } from "@heroicons/react/24/solid";

export default function Success() {
  return (
    <div className="px-0 py-0 text-center text-lg md:px-6">
      <CheckIcon className="mx-auto mb-2 h-16 w-16" />
      <p className="mb-2">Success! Welcome to Ahauz.</p>
      <p>You'll be directed to your dashboard shortly.</p>
    </div>
  );
}
