import { Controller } from "react-hook-form";
import { RadioGroup } from "@headlessui/react";

import FormInputError from "components/Form/Input/Error";
import { classNames } from "utils/helpers";

type RadioGroupType = {
  id: string;
  label: string;
  options: Array<any>;
  error?: any;
  control: any;
  fiftyfifty?: boolean;
};

export default function FormInputRadioGroup({
  id,
  label,
  options,
  error,
  control,
  fiftyfifty = false,
}: RadioGroupType) {
  const optionCount = options.length;
  const fiftyfiftyStyle = "grid-cols-2";

  let groupStyle: string;

  if (fiftyfifty) {
    groupStyle = fiftyfiftyStyle;
  } else {
    switch (optionCount) {
      case 2:
        groupStyle = "grid-cols-1 sm:grid-cols-2";
        break;
      case 3:
        groupStyle = `grid-cols-1 sm:grid-cols-3`;
        break;
      case 4:
        groupStyle = `grid-cols-2 sm:grid-cols-4`;
        break;
      case 5:
        groupStyle = `grid-cols-5 sm:grid-cols-5`;
        break;
      case 10:
        groupStyle = `grid-cols-2 sm:grid-cols-5`;
        break;
      default:
        groupStyle = `grid-cols-3 sm:grid-cols-6`;
        break;
    }
  }

  const optionStyle = (checked: boolean) =>
    classNames(
      checked
        ? "border-transparent bg-brand-green-dark text-white hover:bg-brand-green-dark"
        : "border-gray-300 bg-white text-brand-green-dark hover:bg-gray-50",
      "flex cursor-pointer items-center justify-center rounded-md border px-1 py-2 text-base ring-0 focus:outline-none sm:flex-1"
    );

  return (
    <Controller
      control={control}
      defaultValue=""
      name={id}
      render={({ field }) => (
        <RadioGroup value={field.value} onChange={field.onChange}>
          <RadioGroup.Label className="text-base">{label}</RadioGroup.Label>
          <div className={`mt-2 grid gap-2 md:gap-3 ${groupStyle}`}>
            {options.map((option) => (
              <RadioGroup.Option
                key={option?.value || option}
                value={option?.value || option}
                className={({ checked }) => optionStyle(checked)}
              >
                <RadioGroup.Label as="span">
                  {option?.name || option}
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
          <FormInputError error={error} />
        </RadioGroup>
      )}
    />
  );
}
