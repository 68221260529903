import CTAButton from "components/CTA/Button";
import FormErrors from "components/Form/Errors";
import Loading from "components/Loading";

type Props = {
  onSubmit: Function;
  children: JSX.Element;
  errors?: Array<string> | null;
  canSubmit?: boolean;
  submitCTA?: string;
  submitting?: boolean;
};

export default function Form({
  onSubmit,
  children,
  errors,
  canSubmit,
  submitCTA,
  submitting,
}: Props) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) =>
    onSubmit(event);

  return (
    <form className="space-y-6" onSubmit={handleSubmit} noValidate>
      <FormErrors errors={errors} />
      {children}
      <div>
        {submitting ? (
          <Loading />
        ) : (
          <CTAButton type="submit" enabled={canSubmit} label={submitCTA} full />
        )}
      </div>
    </form>
  );
}
