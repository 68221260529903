import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { MUTATION_UPDATE_EQUITY_CHECK } from "api/mutations/equitycheck";
import { trackEvent } from "analytics";
import AlertError from "components/Alert/Error";
import CTAButton from "components/CTA/Button";
import Loading from "components/Loading";
import FormErrors from "components/Form/Errors";
import FormInputCurrency from "components/Form/Input/Currency";
import EquityStat from "components/Dashboard/Stats/Stat";
import {
  ManualPropertyValueFormInputs,
  StatType,
} from "pages/Dashboard/components/EquityCheck/types";
import { useNotificationStore } from "store/Notifications";
import { useUserStore } from "store/User";
import { asCurrency, inputToFloat } from "utils/currency";
import { COPY_FORM_ERROR_CONTACT_SUPPORT } from "utils/copy";

const schema = yup
  .object({
    valuationManual: yup
      .string()
      .required("Please input your property value")
      .min(0, "Property value must be greater than 0"),
  })
  .required();

export default function PropertyValueStat({
  eqId,
  stat,
  value,
  internalValuation,
  renderStat = true,
}: {
  eqId?: string;
  stat: StatType;
  value?: number;
  internalValuation?: number;
  renderStat?: boolean;
}) {
  const { CURRENCY_SYMBOL, COUNTRY_CODE } = useUserStore(
    (state) => state.userCountry
  );
  const setEquityCheck = useUserStore((state) => state.setEquityCheck);
  const createNotification = useNotificationStore(
    (state) => state.createNotification
  );

  const [open, setOpen] = useState(false);
  const [defaultVal, setDefaultVal] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ManualPropertyValueFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      valuationManual: "",
    },
  });

  const [
    updateEquityCheck,
    {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      reset: resetMutation,
    },
  ] = useMutation(MUTATION_UPDATE_EQUITY_CHECK);

  useEffect(() => {
    if (mutationData?.response?.ok && mutationData?.response?.equityCheck) {
      trackEvent("Property Value manually updated");

      setTimeout(() => {
        setEquityCheck(mutationData.response.equityCheck);
        closeModal();
        createNotification({
          title: "Property Valuation saved!",
          description: "Your dashboard has been updated.",
        });
      }, 500);
    }
    if (!mutationData?.response?.ok) {
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData]);

  useEffect(() => {
    setSubmitting(false);
  }, [mutationError]);

  const formErrors =
    !mutationData?.response?.ok && mutationData?.response?.errors
      ? mutationData?.response?.errors
      : null;

  const closeModal = () => {
    setOpen(false);
    setSubmitting(false);
    setDefaultVal("");
    reset();
    resetMutation();
  };

  const onSubmit: SubmitHandler<ManualPropertyValueFormInputs> = (data) => {
    setSubmitting(true);
    updateEquityCheck({
      variables: {
        input: {
          id: eqId,
          valuationManual: inputToFloat(data.valuationManual),
        },
      },
    });
  };

  /**
   * Trigger form submission using internal valuation
   */
  const revertToInternalValuation = () => {
    trackEvent("Revert manual Property Value");
    setValue("valuationManual", asCurrency(internalValuation));
    setDefaultVal(asCurrency(internalValuation));
    handleSubmit(onSubmit)();
  };

  return (
    <EquityStat stat={stat} value={value} renderStat={renderStat}>
      <>
        {renderStat && value && (
          <div className="absolute right-0 top-0 p-[inherit]">
            <CTAButton
              label="Not right?"
              styling="!py-0.5 md:!py-1 px-3 md:px-5 bg-brand-green-medium hover:bg-brand-green-bright border-none rounded:lg md:rounded-xl text-sm"
              onClick={() => setOpen(true)}
            />
          </div>
        )}

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform self-center overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-orange-medium sm:mx-0 sm:h-10 sm:w-10">
                          <QuestionMarkCircleIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-brand-green-dark"
                          >
                            Update Property Value
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-base text-gray-500">
                              Is the estimate of your property value not
                              accurate? Please share your estimated current
                              market value of the property and we'll update your
                              dashboard accordingly. We will use the value you
                              share for all calculations.
                            </p>
                            <p className="mt-2 text-base italic text-gray-500">
                              The estimated valuation of your property is:{" "}
                              <br /> {CURRENCY_SYMBOL}
                              {asCurrency(
                                internalValuation,
                                COUNTRY_CODE
                              )}.{" "}
                              <button
                                type="button"
                                className="link italic"
                                onClick={() => revertToInternalValuation()}
                              >
                                Use this value.
                              </button>
                            </p>
                          </div>
                          {formErrors && (
                            <div className="mb-5 mt-4 sm:mb-4">
                              <FormErrors errors={formErrors} />
                            </div>
                          )}
                          {mutationError && (
                            <div className="mb-5 mt-4 sm:mb-4">
                              <AlertError title="Can not update Property Value">
                                <p>{COPY_FORM_ERROR_CONTACT_SUPPORT}</p>
                              </AlertError>
                            </div>
                          )}
                          <div className="mb-5 mt-4 sm:mb-4">
                            <FormInputCurrency
                              id="valuationManual"
                              disabled={submitting}
                              register={register}
                              defaultVal={defaultVal}
                              currency={CURRENCY_SYMBOL}
                              countryCode={COUNTRY_CODE}
                              error={errors?.valuationManual?.message}
                            />
                          </div>
                        </div>
                      </div>
                      {submitting || mutationLoading ? (
                        <Loading />
                      ) : (
                        <div className="sm:ml-10 sm:flex sm:justify-end sm:pl-4">
                          <div className="sm:ml-3">
                            <CTAButton full type="submit" label="Update" />
                          </div>
                          <div className="order-first mt-3 sm:mt-0">
                            <CTAButton
                              full
                              secondary
                              label="Cancel"
                              onClick={() => setOpen(false)}
                            />
                          </div>
                        </div>
                      )}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    </EquityStat>
  );
}
