import BookACall from "components/BookACall";

export default function ActionCTA({
  name,
  bookACall = true,
  bookingLink,
  product,
  children,
}: {
  name: string;
  bookACall?: boolean;
  bookingLink?: string;
  product?: string;
  children: JSX.Element;
}) {
  const elClass = name.replace(/\s+/g, "-").toLowerCase();

  return (
    <div
      className={`action-${elClass} text-bg-brand-green-dark flex h-full max-w-lg flex-col justify-between space-y-4 rounded-2.5xl bg-white px-5 py-4 text-lg shadow-xl`}
    >
      {children}
      {bookACall && (
        <div className="w-full">
          <BookACall bookingLink={bookingLink} product={product} />
        </div>
      )}
    </div>
  );
}
