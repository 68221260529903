import { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { MUTATION_CANCEL_APPOINTMENT } from "api/mutations/appointments";

import CTAButton from "components/CTA/Button";
import { useNotificationStore } from "store/Notifications";
import { AppointmentType } from "ts/types";

const CANCEL_APPOINTMENT_ERROR_NOTIFICTION = {
  type: "error",
  title: "Appointment error",
  description:
    "Sorry, we can't cancel this appointment right now. Please try again or contact us.",
};

type AppointmentPropsType = {
  appointment: AppointmentType;
  onReschedule: Function;
  onCancel: Function;
};

export default function Appointment({
  appointment,
  onReschedule,
  onCancel,
}: AppointmentPropsType) {
  const createNotification = useNotificationStore(
    (state) => state.createNotification
  );

  const [
    cancelAppointment,
    {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      reset: resetMutation,
    },
  ] = useMutation(MUTATION_CANCEL_APPOINTMENT);

  /**
   * Handle mutation response
   */
  useEffect(() => {
    if (mutationData?.response?.ok) {
      onCancel();
    }
    if (mutationData?.response && !mutationData.response.ok) {
      createNotification(CANCEL_APPOINTMENT_ERROR_NOTIFICTION);
      resetMutation();
    }
  }, [mutationData, onCancel, resetMutation, createNotification]);

  /**
   * Handle mutation error
   */
  useEffect(() => {
    if (mutationError) {
      createNotification(CANCEL_APPOINTMENT_ERROR_NOTIFICTION);
      resetMutation();
    }
  }, [mutationError, resetMutation, createNotification]);

  const triggerCancelAppointment = (slug: string, cancelId: string) => {
    if (slug && cancelId) {
      cancelAppointment({
        variables: {
          slug,
          cancelId,
        },
      });
    } else {
      createNotification(CANCEL_APPOINTMENT_ERROR_NOTIFICTION);
    }
  };

  return (
    <li className="rounded-xl bg-gray-200 p-4">
      <div className="items-center sm:flex">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 grid-cols-1 pr-4 md:grid">
            <div>
              <p className="text-brang-green-medium text-base font-medium">
                {appointment.startTime}
              </p>
              <p className="mt-1 flex items-center text-base text-brand-copy-light">
                {appointment.title}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-2 flex justify-end sm:block md:mt-0">
          <div className="flex">
            <CTAButton
              label="Cancel"
              onClick={() =>
                triggerCancelAppointment(appointment.slug, appointment.cancelId)
              }
              enabled={!mutationLoading}
              loading={mutationLoading}
              styling="bg-white  border-none "
            />
            {appointment.rescheduleUrl && (
              <div className="ml-2">
                <CTAButton
                  label="Reschedule"
                  onClick={() => {
                    onReschedule(appointment.rescheduleUrl);
                  }}
                  styling="text-white border-none bg-brand-green-dark hover:bg-brand-green-dark"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
