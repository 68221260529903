import { useEffect, useState } from "react";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { asCurrency, inputToFloat } from "utils/currency";
import { DEFAULT_COUNTRY, DEFAULT_CURRENCY } from "country-config";

type FormInputCurrencyProps = FormInputProps & {
  currency?: string;
  countryCode?: string;
  allowZero?: boolean;
};

export default function FormInputCurrency({
  id,
  label,
  error,
  register,
  defaultVal = "",
  disabled = false,
  helperText = "",
  currency = DEFAULT_CURRENCY,
  countryCode = DEFAULT_COUNTRY,
  allowZero = false,
}: FormInputCurrencyProps) {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (defaultVal) {
      setInputValue(
        asCurrency(inputToFloat(defaultVal), countryCode, allowZero)
      );
    }
  }, [defaultVal, countryCode, allowZero]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    setInputValue(asCurrency(inputToFloat(value), countryCode, allowZero));
  };

  const inputProps =
    id && register
      ? {
          ...register(id, {
            required: true,
            onChange: handleOnChange,
          }),
        }
      : {};

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className="mt-1 sm:col-span-2">
        {helperText && (
          <label className="mb-1 block text-base text-brand-copy-lighter">
            {helperText}
          </label>
        )}
        <div className="flex rounded-md">
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-base text-gray-500">
            {currency}
          </span>
          <input
            type="text"
            id={id}
            name={id}
            required
            className="block w-full appearance-none rounded-none rounded-r-md border border-gray-300 px-3 py-2 text-base placeholder-gray-400 focus:border-brand-orange-medium focus:outline-none focus:ring-brand-orange-medium"
            value={inputValue}
            disabled={disabled}
            inputMode="numeric"
            pattern="[0-9]*"
            {...inputProps}
          />
        </div>
        <FormInputError error={error} />
      </div>
    </div>
  );
}
