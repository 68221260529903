export default function IconArrowLeft() {
  return (
    <svg
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-5.72901e-07 13.0002L12.722 26L16 22.6652L6.54135 13L16 3.33482L12.722 -5.51569e-07L-5.72901e-07 13.0002Z"
        fill="#0D493B"
      />
    </svg>
  );
}
