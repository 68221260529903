import CTAButton from "components/CTA/Button";
import { classNames } from "utils/helpers";

type StepActionsType = {
  goBack?: Function;
  loading?: boolean;
  labelNext?: string;
};

export default function StepActions({
  goBack,
  loading = false,
  labelNext = "Next",
}: StepActionsType) {
  const canGoBack = goBack && !loading;
  return (
    <div
      className={classNames(
        canGoBack ? "justify-between" : "justify-end",
        "mt-6 flex md:mt-8"
      )}
    >
      {canGoBack && (
        <CTAButton secondary label="Previous" onClick={() => goBack()} />
      )}
      <CTAButton label={labelNext} type="submit" loading={loading} />
    </div>
  );
}
