import { Chart, ScriptableTooltipContext, TooltipItem } from "chart.js";

const getOrCreateTooltip = (chart: Chart, label: string) => {
  const tooltipClass = `point-${label.toLocaleLowerCase().replaceAll(" ", "")}`;

  if (!chart?.canvas?.parentNode) {
    return null;
  }

  let tooltipEl = chart.canvas.parentNode.querySelector(`div.${tooltipClass}`);

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("ec-tooltip");
    tooltipEl.classList.add(tooltipClass);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl as HTMLElement;
};

export const tooltipHandler = (context: ScriptableTooltipContext<any>) => {
  // Tooltip Element
  const { chart, tooltip } = context;

  if (!tooltip.dataPoints || !tooltip.body) {
    return;
  }

  const dupes: any[] = [];

  tooltip.dataPoints.forEach((dataPoint: TooltipItem<any>) => {
    const thisTooltipTitle = dataPoint.dataset.label;
    const tooltipEl = getOrCreateTooltip(chart, thisTooltipTitle);

    if (!tooltipEl) {
      return;
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    const datasetTitle = document.createElement("span");
    datasetTitle.classList.add("text-sm", "md:text-base", "uppercase");
    datasetTitle.textContent = thisTooltipTitle;

    const datasetValue = document.createElement("span");
    datasetValue.classList.add("text-2xl", "md:text-2.5xl", "mb-1");
    datasetValue.textContent = `£${dataPoint.formattedValue}`;

    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    tooltipEl.appendChild(datasetValue);
    tooltipEl.appendChild(datasetTitle);

    const { x: positionX, y: positionY } = dataPoint.element;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.left = positionX + 24 + "px";
    tooltipEl.style.top = positionY + 30 + "px";

    // Always show Equity over others
    if (dupes.length === 0) {
      dupes.push(tooltipEl);
    }
    if (dupes.length === 1 && tooltipEl !== dupes[0]) {
      const tooltipElTop = Number(tooltipEl.style.top.replace("px", ""));
      const dupeTop = Number(dupes[0].style.top.replace("px", ""));

      if (tooltipElTop >= dupeTop - 15 && tooltipElTop <= dupeTop + 15) {
        dupes[0].remove();
      }
    }
  });
};

export const triggerLatestMonthTooltipShow = (
  chart: Chart,
  datasetIndex: number,
  reset: boolean = false
) => {
  const meta = chart.getDatasetMeta(datasetIndex);

  const point = meta.data
    .slice()
    .reverse()
    .find((item: any) => !item.skip);

  if (point) {
    if (reset) {
      document.querySelectorAll(".ec-tooltip").forEach((e) => e.remove());
    }

    const rect = chart.canvas.getBoundingClientRect();
    const evt = new MouseEvent("click", {
      clientX: rect.left + point.x,
      clientY: rect.top + point.y,
    });
    const node = chart.canvas;
    node.dispatchEvent(evt);
  }
};

export const scrollLatestMonthTooltipIntoView = () => {
  const targetTooltip = document.querySelector(".ec-tooltip");
  if (targetTooltip) {
    targetTooltip.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    });
  }
};

export const filterTooltip = (chart: Chart, datasetIndex: number) => {
  // Skipping for now
  return;
  // triggerLatestMonthTooltipShow(chart, datasetIndex, true);
  // setTimeout(() => {
  //   scrollLatestMonthTooltipIntoView();
  // }, 500);
};
