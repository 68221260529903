import create from "zustand";
import { GetStartedState } from "store/GetStarted/types";

const DEFAULT_SUBMISSION = {
  property: {},
  account: {},
};

const DEFAULT_STEPS = [
  {
    id: 1,
    number: 1,
    name: "Your Property",
    status: "current",
    duration: 1,
    progress: 0,
  },
  {
    id: 2,
    number: 2,
    name: "Your Details",
    status: "upcoming",
    duration: 1,
    progress: 0,
  },
  {
    id: 3,
    number: 3,
    name: "Your Results",
    status: "upcoming",
    duration: null,
    progress: 0,
  },
];

export const useGetStartedStore = create<GetStartedState>()((set, get) => ({
  submission: DEFAULT_SUBMISSION,
  steps: DEFAULT_STEPS,
  updateSubmission: (step: string, data: Object) => {
    set((state) => (state.submission[step] = data));
    return get().submission;
  },

  updateStepPercent: (stepId: number, percent: number) =>
    set((state) => ({
      steps: state.steps.map((step) => {
        if (step.id === stepId) {
          return {
            ...step,
            progress: percent,
          };
        }
        return step;
      }),
    })),

  completeStep: (stepId: number, fail = false) =>
    set((state) => ({
      steps: state.steps.map((step) => {
        if (step.id === stepId) {
          return {
            ...step,
            status: fail ? "fail" : "complete",
          };
        }
        if (step.id === stepId + 1) {
          return {
            ...step,
            status: "current",
          };
        }
        return step;
      }),
    })),

  resetSubmission: () =>
    set(() => ({
      submission: DEFAULT_SUBMISSION,
      steps: DEFAULT_STEPS,
    })),
}));
