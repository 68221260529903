import {
  ArrowDownOnSquareStackIcon,
  ArrowTopRightOnSquareIcon,
  HomeIcon,
  LockClosedIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";

import ActionCTA from "components/ActionCTA";
import ActionCTATitle from "components/ActionCTA/Title";
import { CRM } from "settings";
import { EquityCheckType } from "ts/types";
import { asCurrency } from "utils/currency";

function ProductActionCTA({
  name,
  bookingLink,
  title,
  copy,
  product,
  children,
}: {
  name: string;
  bookingLink?: string;
  title: string;
  copy: string;
  product: string;
  children: JSX.Element;
}) {
  return (
    <div className="shrink-0 basis-80">
      <ActionCTA name={name} bookingLink={bookingLink} product={`p_${product}`}>
        <div>
          <ActionCTATitle title={title}>{children}</ActionCTATitle>
          <p className="mt-4" dangerouslySetInnerHTML={{ __html: copy }} />
        </div>
      </ActionCTA>
    </div>
  );
}

export default function ProductCTAs({
  equityCheck,
  filter = [],
}: {
  equityCheck: EquityCheckType;
  filter: string[];
}) {
  const productLoanValue = (productValue: number) =>
    `£${asCurrency(productValue)}`;

  /**
   * Condition to assert product can be rendered
   * @param productKey
   * @param productValue
   * @returns bool
   */
  const canRenderProduct = (productKey: string, productValue: number) => {
    if (productValue) {
      if (filter.length === 0) {
        return true;
      }

      if (filter.includes(productKey)) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {canRenderProduct(
        "productEquityRelease",
        equityCheck.productEquityRelease
      ) && (
        <ProductActionCTA
          name="Equity Release"
          product="equity_release"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_EQUITY_RELEASE}
          title="Equity release"
          copy={`Get tax-free cash of up to <span class="font-bold">${productLoanValue(
            equityCheck.productEquityRelease
          )}</span> from your home equity. No mandatory monthly repayments.`}
        >
          <ArrowTopRightOnSquareIcon className="h-7 w-7" />
        </ProductActionCTA>
      )}
      {canRenderProduct("productRemortgage", equityCheck.productRemortgage) && (
        <ProductActionCTA
          name="Remortgage"
          product="remortgage"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_REMORTGAGE}
          title="Remortgage now"
          copy={`You can remortgage your current mortgage or borrow more, up to <span class="font-bold">${productLoanValue(
            equityCheck.productRemortgage
          )}</span>`}
        >
          <ReceiptPercentIcon className="h-8 w-8" />
        </ProductActionCTA>
      )}
      {canRenderProduct(
        "productBuyProperty",
        equityCheck.productBuyProperty
      ) && (
        <ProductActionCTA
          name="Buy Property"
          product="buy_property"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_BUY_PROPERTY}
          title="Buy a new property"
          copy={`You can use your equity to buy a new home or get a Buy To Let of up to <span class="font-bold">${productLoanValue(
            equityCheck.productBuyProperty
          )}</span>`}
        >
          <HomeIcon className="h-8 w-8" />
        </ProductActionCTA>
      )}
      {canRenderProduct("productEquityLoan", equityCheck.productEquityLoan) && (
        <ProductActionCTA
          name="Equity Loan"
          product="equity_loan"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_EQUITY_LOAN}
          title="Get a loan"
          copy={`Get up to <span class="font-bold">${productLoanValue(
            equityCheck.productEquityLoan
          )}</span> from your home equity`}
        >
          <>£</>
        </ProductActionCTA>
      )}
      {canRenderProduct(
        "productDebtConsolidation",
        equityCheck.productDebtConsolidation
      ) && (
        <ProductActionCTA
          name="Debt Consolidation"
          product="debt_consolidation"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL_DEBT_CONSOLIDATION}
          title="Consolidate your debts"
          copy={`Use your home equity to consolidate debts of up to <span class="font-bold">${productLoanValue(
            equityCheck.productDebtConsolidation
          )}</span>`}
        >
          <ArrowDownOnSquareStackIcon className="h-8 w-8" />
        </ProductActionCTA>
      )}
      {canRenderProduct(
        "productMortgageProtection",
        equityCheck.productMortgageProtection
      ) && (
        <ProductActionCTA
          name="Mortgage Protection"
          product="mortgage_protection"
          bookingLink={CRM.APPOINTMENT_BOOKING_URL}
          title="Mortgage Protection"
          copy="Make sure you have tailored protection, e.g. income protection or critical illness cover"
        >
          <LockClosedIcon className="h-8 w-8" />
        </ProductActionCTA>
      )}
    </>
  );
}
